import {
  UPDATE_CV_LOADING,
  UPDATE_CV_SUCCESS,
  UPDATE_CV_FAILURE,
} from "redux/types";
import { Dispatch } from "redux";
import { createNewItemService } from "services/createNewItemService";
import { updateUserCvLink } from "constants/endPoints";

export const updateCvAction =
  (file: any, userId: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: UPDATE_CV_LOADING, payload: true });
      const response = await createNewItemService(
        `${updateUserCvLink}`,
        {
          UserId: userId,
          CVFile: file,
        },
        "",
        "formData"
      );
      dispatch({ type: UPDATE_CV_SUCCESS, payload: response.data });
      callback();
    } catch (err) {
      dispatch({ type: UPDATE_CV_FAILURE, payload: err });
    } finally {
      dispatch({ type: UPDATE_CV_LOADING, payload: false });
    }
  };

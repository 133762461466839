import {
  GET_COMPANY_JOBS_LOADING,
  GET_COMPANY_JOBS_SUCCESS,
  GET_COMPANY_JOBS_FAILURE,
} from "redux/types";

const initialState = {
  companyJobsLoading: false,
  companyJobs: null,
  errors: null,
};

export const getCompanyJobsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_COMPANY_JOBS_LOADING:
      return { ...state, companyJobsLoading: action.payload };
    case GET_COMPANY_JOBS_SUCCESS:
      return { ...state, companyJobs: action.payload };
    case GET_COMPANY_JOBS_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};

import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import * as yup from "yup";
import { updateUserProfileAction } from "redux/actions/profileDataActions/updateUserProfileAction";
import { listProfileDataAction } from "redux/actions/profileDataActions/listProfileDataAction";
import { notification } from "antd";
import { BASE_IMAGE_URL } from "constants/constants";

interface UpdateUserProfileModel {
  FirstName: string;
  LastName: string;
  ImageFile: string;
  UniversityId: number;
  FacultyId: string;
  Description: string;
  JobLevelId: number;
  GraduationStatusId: number;
  JobLocationTypeId: number;
  MajorIds: any[];
  SkillIds: any[];
  CountryId: number;
  CityId: number;
}

const useUpdateUserProfileFormik = (setState?: any, uploadCvFormik?: any) => {
  const dispatch: any = useDispatch();

  const { profileData } = useSelector(
    (state: RootState) => state.listProfileDataReducer
  );

  // const getData = async () => {
  //   await dispatch(
  //     listProfileDataAction(async (data: any) => {
  //       formik.setFieldValue("FirstName", data?.firstName);
  //       formik.setFieldValue("LastName", data?.lastName);
  //       formik.setFieldValue("ImageFile", BASE_IMAGE_URL + data?.profileImage);
  //       formik.setFieldValue("UniversityId", data?.universityId);
  //       formik.setFieldValue("FacultyId", data?.facultyId);
  //       formik.setFieldValue("Description", data?.description);
  //       formik.setFieldValue("JobLevelId", data?.jobLevelId);
  //       formik.setFieldValue("GraduationStatusId", data?.jobLevelId);
  //       formik.setFieldValue("JobLocationTypeId", data?.jobLocationTypeId);
  //       formik.setFieldValue("CountryId", data?.countryId);
  //       formik.setFieldValue("CityId", data?.cityId);
  //       await dispatch(
  //         getUserCvAction(data?.userId, (dataCV: any) => {
  //           uploadCvFormik.setFieldValue("pdfFile", dataCV.fileName);
  //         })
  //       );
  //     })
  //   );
  // };

  // useEffect(() => {
  //   if (window.location.pathname === "/profile") {
  //     getData();
  //   }
  // }, []);

  const formik = useFormik({
    initialValues: {
      FirstName: profileData?.firstName,
      LastName: profileData?.lastName,
      ImageFile: BASE_IMAGE_URL + profileData?.profileImage,
      UniversityId: profileData?.universityId,
      FacultyId: profileData?.facultyId,
      Description: profileData?.description,
      JobLevelId: profileData?.jobLevelId,
      GraduationStatusId: profileData?.jobLevelId,
      JobLocationTypeId: profileData?.jobLocationTypeId,
      MajorIds: profileData?.user?.userMajors?.map(
        (item: any) => item?.major.majorId
      ),
      SkillIds: profileData?.user?.userSkills?.map(
        (item: any) => item?.skill.skillId
      ),
      CountryId: profileData?.countryId,
      CityId: profileData?.cityId,
    },
    validationSchema: yup.object({
      FirstName: yup.string().required("This field is required"),
      LastName: yup.string().required("This field is required"),
      // ImageFile: yup
      //   .mixed()
      //   .required("This field is required")
      //   .test(
      //     "fileSize",
      //     "File too large",
      //     (value: any) => value && value.size <= 5242880
      //   ),
      MajorIds: yup
        .array()
        .min(1, "At least one major is required")
        .required("This field is required"),
      SkillIds: yup
        .array()
        .min(1, "At least one skill is required")
        .required("This field is required"),
    }),
    onSubmit: async (values: UpdateUserProfileModel) => {
      // console.log(values);
      await dispatch(
        updateUserProfileAction(
          profileData?.userProfileId,
          {
            UserId: profileData?.userId,
            FirstName: values.FirstName,
            LastName: values.LastName,
            ImageFile:
              typeof values?.ImageFile === "string" ? null : values.ImageFile,
            UniversityId: values.UniversityId,
            FacultyId: values.FacultyId,
            Description: values.Description,
            JobLevelId: values.JobLevelId,
            GraduationStatusId: values.GraduationStatusId,
            JobLocationTypeId: values.JobLocationTypeId,
            MajorIds: values.MajorIds?.map((item: any) => item?.majorId),
            SkillIds: values.SkillIds,
            CountryId: values.CountryId,
            CityId: values.CityId,
          },
          () => {
            notification.success({
              message: "Profile Updated Successfully",
            });
            dispatch(listProfileDataAction());
          }
        )
      );
    },
  });

  return formik;
};

export { useUpdateUserProfileFormik };

import ProgressBar from "./ProgressBar";
import { useNavigate } from "react-router-dom";
import { PinIcon } from "assets/icons";
import { BASE_IMAGE_URL } from "constants/constants";

function MatchIntern(intern?: any) {
  const navigate = useNavigate();

  const matchmakingPercentage = Number(
    intern.intern.matchmakingPercentage.toString().includes(".")
      ? intern.intern.matchmakingPercentage.toString().substring(0, 4)
      : intern.intern.matchmakingPercentage.toString()
  );

  const vacancyWorkPlaceId = intern?.intern?.matchedVacancy?.vacancyWorkPlaceId;

  return (
    <div
      data-aos="fade-up"
      data-aos-duration="900"
      // to={"/matchedInternships"}
      className=" w-full max-h-[234px] rounded-[15px] bg-white flex flex-col gap-[10px] shadow-[0px_0px_20px_-10px_black]"
    >
      <div className="p-[20px] pb-[0px] flex flex-col gap-[10px]">
        {/* header  */}
        <p
          onClick={() =>
            navigate(`/jobDetails/${intern?.intern?.matchedVacancy?.vacancyId}`)
          }
          className="font-Avenir_demi text-[20px] text-pro_text_dark cursor-pointer hover:text-[#69b1ff] duration-300 line-clamp-1 text-ellipsis"
        >
          {intern?.intern?.matchedVacancy.title}
        </p>

        {/* company name  */}
        <div className="flex items-center gap-[10px] text-pro_text_dark">
          <img
            src={
              BASE_IMAGE_URL +
              intern?.intern?.matchedVacancy?.company?.profileLogo
            }
            alt="profile"
            className="w-[32px] h-[32px] rounded-full object-cover"
          />
          <p
            onClick={() =>
              navigate(
                `/companydetails/${intern?.intern?.matchedVacancy?.company?.companyId}`
              )
            }
            className="font-Avenir_demi text-[16px] cursor-pointer hover:text-[#69b1ff] duration-300"
          >
            {intern?.intern?.matchedVacancy?.company?.companyName}
          </p>
        </div>

        {/* location  */}
        <div className="flex items-center gap-[10px] text-pro_text_dark">
          <div className="w-[20px] h-[25px]">
            <img src={PinIcon} alt="location" />
          </div>
          <p className="font-Avenir_regular text-[16px]">Cairo, Egypt</p>
          <div className="font-Avenir_demi text-[14px] p-[5px] rounded-[20px] bg-pro_bg text-white">
            {intern?.intern?.matchedVacancy?.vacancyLevelId === 0
              ? ""
              : intern?.intern?.matchedVacancy?.vacancyLevelId === 1
              ? "Internship"
              : intern?.intern?.matchedVacancy?.vacancyLevelId === 2
              ? "EntryLevel "
              : // : intern?.intern?.matchedVacancy?.vacancyLevelId === 3
                // ? "Hybrid"
                ""}
          </div>
          <div className="font-Avenir_demi text-[14px] p-[5px] rounded-[20px] bg-pro_bg text-white">
            {vacancyWorkPlaceId === 0
              ? ""
              : vacancyWorkPlaceId === 1
              ? "OnSite"
              : vacancyWorkPlaceId === 2
              ? "Remotly"
              : vacancyWorkPlaceId === 3
              ? "Hybrid "
              : ""}
          </div>
        </div>

        {/* description */}
        <div>
          <p className="font-Avenir_regular text-[13px] text-[#444444] min-h-[60px] max-h-[60px] line-clamp-3 text-ellipsis">
            {intern?.intern?.matchedVacancy?.description}
          </p>
        </div>
      </div>

      {/* progress bar */}
      <ProgressBar value={matchmakingPercentage} />
    </div>
  );
}

export default MatchIntern;

import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import CountUp from "react-countup";
ChartJS.register(ArcElement, Tooltip, Legend);

interface DonutValue {
  value: number;
}

function DonutChart({ value }: DonutValue) {
  const backgroundColor = (value: number) => {
    if (value > 50 && value <= 100) {
      return "#E3FF66";
    }
    //  else if (value < 70 && value > 40) {
    //   return "#F7C500";
    // }
    else if (value <= 50 && value >= 0) {
      return "#9AADBF";
    } else {
      return "#F1F1F1";
    }
  };

  const textColor = backgroundColor(value);

  const data = {
    datasets: [
      {
        data: [value, 100 - value],
        backgroundColor: [textColor, "#F1F1F1"],
        cutout: "65%",
      },
    ],
  };

  const options = {
    aspectRatio: 1,
    maintainAspectRatio: false,
    responsive: true,
    animation: {
      animateRotate: true,
      duration: 2000,
    },
  };

  return (
    <div className="sm:absolute top-[5%] right-[2%]">
      <div className="w-[95px] h-[95px] relative">
        <Doughnut data={data} options={options} />

        {/* text inside */}
        <div
          className="absolute top-[35%] left-[27%] font-Avenir_demi text-[11px]"
          // style={{
          //   color: `${textColor}`,
          // }}
        >
          <div className="flex flex-col items-center text-pro_text_dark ">
            <p>
              <CountUp start={0} end={value} duration={2} />%
            </p>

            <p>Matched</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DonutChart;

import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { generateOtpResetPassword } from "redux/actions/JoinUsAction/ResetPasswordActions/generateOtpResetPasswordActions";

interface generateOtpTypes {
  email: string;
}

export const useGenerateOtpResetPasswordFormik = (
  setResetPop: any,
  setEmail: any
) => {
  const dispatch: any = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object({
      email: yup.string().email().required("please enter this field"),
    }),
    onSubmit: async (values: generateOtpTypes) => {
      await dispatch(
        generateOtpResetPassword(
          {
            email: values.email,
          },
          () => {
            setResetPop("validateOtpResetPassword");
            setEmail(values.email);
          }
        )
      );
    },
  });

  return formik;
};

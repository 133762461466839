import {
  JOB_DETAILS_LOADING,
  JOB_DETAILS_SUCCESS,
  JOB_DETAILS_FAILURE,
} from "redux/types";

const initialState = {
  jobDetailsLoading: false,
  jobDetails: [],
  errors: null,
};

export const JobDetailsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case JOB_DETAILS_LOADING:
      return { ...state, jobDetailsLoading: action.payload };
    case JOB_DETAILS_SUCCESS:
      return { ...state, jobDetails: action.payload.data };
    case JOB_DETAILS_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};

import { Select, Spin } from "antd";
import { DropdownIcon, LogoIcon, erroricon } from "assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useDropdown } from "hooks/useDropdown";
import { listCitiesAction } from "redux/actions/citiesActions/listCitiesAction";
import { useState } from "react";
import { useUserWorkLocationFormik } from "forms/JoinUsForms/SginUpForms/userWorkLocationFormik";
import { listAreasAction } from "redux/actions/areasActions/listAreasAction";

interface UserWorkLocationProps {
  activePop: string;
  setActivePop: React.Dispatch<React.SetStateAction<any>>;
}

const UserWorkLocation = ({
  activePop,
  setActivePop,
}: UserWorkLocationProps) => {
  const dispatch: any = useDispatch();

  const formik = useUserWorkLocationFormik(setActivePop);

  ////////////////////////// country dropdown
  const { countriesLoading, countryDropdown } = useDropdown();

  ////////////////////////// city dropdown
  const { citiesLoading, cities } = useSelector(
    (state: RootState) => state.listCitiesReducer
  );

  const citiesDropdown = cities?.map((item: any) => {
    return {
      label: item.cityNameEn,
      value: item.cityId,
    };
  });

  ///////////////////////// area dropdown
  const { areasLoading, areas } = useSelector(
    (state: RootState) => state.listAreasReducer
  );

  const areasDropdown = areas?.map((item: any) => {
    return {
      label: item.areaNameEn,
      value: item.areaId,
    };
  });

  ///////////////////////// location type
  const [locationType, setLocationType] = useState(
    formik.values.location_type === "1"
      ? "onSite"
      : formik.values.location_type === "2"
      ? "remote"
      : formik.values.location_type === "3"
      ? "hybrid"
      : ""
  );

  ///////////////////////// handle loading
  const { addWorkLocationLoading } = useSelector(
    (state: RootState) => state.addWorkLocationReducer
  );

  // console.log(areas);

  return (
    <section
      data-aos="zoom-out"
      data-aos-duration="400"
      className="fixed top-0 right-0 w-full h-screen bg-black/55 flex items-center justify-center z-10"
    >
      <div className="relative lg:w-[410px] p-[30px] bg-pro_bg rounded-[15px] flex flex-col gap-[15px] text-pro_text_dark">
        {/*************************** logo section  **********************/}
        <div className="relative flex items-center justify-center">
          {/* logo */}
          <div>
            <img src={LogoIcon} alt="logo" />
          </div>

          {/* close button */}
          <div
            // onClick={() => setActivePop("")}
            className="absolute top-0 right-0 cursor-pointer"
          >
            {/* <img src={CloseIcon} alt="logo" /> */}
          </div>
        </div>

        {/* Work Location And Its Type */}
        <div className="font-Avenir_bold text-[20px] text-white flex items-center justify-center">
          Work Location And Its Type
        </div>

        {/**************** Experience options section  *****************/}
        <Spin spinning={addWorkLocationLoading}>
          <form
            onSubmit={formik?.handleSubmit}
            className="flex flex-col gap-[15px] text-white"
          >
            {/* select your country ****************************/}
            <Spin spinning={countriesLoading}></Spin>
            <div className="flex flex-col gap-y-2">
              <Select
                className="w-full h-[48px] !rounded-[15px] !focus:outline-none placeholder-white/55"
                placeholder="Country"
                suffixIcon={<img src={DropdownIcon} alt="dropdown-icon" />}
                options={countryDropdown}
                value={
                  formik.values.country === "" ? null : formik.values.country
                }
                onSelect={(value: any) => {
                  formik.setFieldValue("country", value);
                  dispatch(listCitiesAction(value?.toString()));
                }}
              />

              {/* errors section  */}
              <div className="mt-[-5px] w-full flex items-center">
                {formik.errors.country && (
                  <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                    <img className="w-[22px]" src={erroricon} alt="" />{" "}
                    {formik.errors.country}
                  </p>
                )}
              </div>
            </div>

            {/* select your city *******************************/}
            <Spin spinning={citiesLoading}></Spin>
            <div className="flex flex-col gap-y-2">
              <Select
                className="w-full h-[48px] !rounded-[15px] !focus:outline-none placeholder-white/55"
                placeholder="City"
                suffixIcon={<img src={DropdownIcon} alt="dropdown-icon" />}
                options={citiesDropdown}
                value={formik.values.city === "" ? null : formik.values.city}
                onSelect={(value: any) => {
                  formik.setFieldValue("city", value);
                  dispatch(listAreasAction(value?.toString()));
                }}
              />

              {/* errors section  */}
              <div className="mt-[-5px] w-full flex items-center">
                {formik.errors.city && (
                  <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                    <img className="w-[22px]" src={erroricon} alt="" />{" "}
                    {formik.errors.city}
                  </p>
                )}
              </div>
            </div>

            {/* select your area *******************************/}
            <Spin spinning={areasLoading}></Spin>
            <div className="flex flex-col gap-y-2">
              <Select
                className="w-full h-[48px] !rounded-[15px] !focus:outline-none placeholder-white/55"
                placeholder="Area"
                suffixIcon={<img src={DropdownIcon} alt="dropdown-icon" />}
                options={areasDropdown}
                value={formik.values.area === "" ? null : formik.values.area}
                onSelect={(value: any) => {
                  formik.setFieldValue("area", value);
                }}
              />

              {/* errors section  */}
              <div className="mt-[-5px] w-full flex items-center">
                {formik.errors.area && (
                  <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                    <img className="w-[22px]" src={erroricon} alt="" />{" "}
                    {formik.errors.area}
                  </p>
                )}
              </div>
            </div>

            {/* location type **********************************/}
            <div className="w-full flex flex-col gap-4">
              <p className="font-Avenir_regular text-[14px]">
                Select location type:
              </p>

              {/* options */}
              <div className="flex items-center gap-3">
                {/* onsite  */}
                <div
                  onClick={() => {
                    setLocationType("onsite");
                    formik.setFieldValue("location_type", "1");
                  }}
                  className={
                    locationType === "onsite"
                      ? "w-full flex items-center justify-center h-[48px] border border-pro_text_light rounded-[14px] cursor-pointer bg-pro_text_light text-dashboard_primary font-Avenir_demi"
                      : "w-full flex items-center justify-center h-[48px] border border-pro_text_light rounded-[14px] cursor-pointer font-Avenir_demi"
                  }
                >
                  On site
                </div>

                {/* Remote */}
                <div
                  onClick={() => {
                    setLocationType("remote");
                    formik.setFieldValue("location_type", "2");
                  }}
                  className={
                    locationType === "remote"
                      ? "w-full flex items-center justify-center h-[48px] border border-pro_text_light rounded-[14px] cursor-pointer bg-pro_text_light text-dashboard_primary font-Avenir_demi"
                      : "w-full flex items-center justify-center h-[48px] border border-pro_text_light rounded-[14px] cursor-pointer font-Avenir_demi"
                  }
                >
                  Remote
                </div>

                {/* Hybrid */}
                <div
                  onClick={() => {
                    setLocationType("hybrid");
                    formik.setFieldValue("location_type", "3");
                  }}
                  className={
                    locationType === "hybrid"
                      ? "w-full flex items-center justify-center h-[48px] border border-pro_text_light rounded-[14px] cursor-pointer bg-pro_text_light text-dashboard_primary font-Avenir_demi"
                      : "w-full flex items-center justify-center h-[48px] border border-pro_text_light rounded-[14px] cursor-pointer font-Avenir_demi"
                  }
                >
                  Hybrid
                </div>
              </div>

              {/* errors section  */}
              <div className="mt-[-10px] w-full flex items-center">
                {formik.errors.location_type && (
                  <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                    <img className="w-[22px]" src={erroricon} alt="" />{" "}
                    {formik.errors.location_type}
                  </p>
                )}
              </div>
            </div>

            {/* next button *************************************/}
            <button
              type="submit"
              className="w-full h-[40px] bg-[#DCDEE3] text-pro_text_dark text-[14px] font-Avenir_demi rounded-[15px] hover:text-pro_text_light hover:bg-pro_text_dark duration-300"
            >
              Next
            </button>
          </form>
        </Spin>
      </div>
    </section>
  );
};

export default UserWorkLocation;

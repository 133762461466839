import React, { useEffect, useRef, useState } from "react";
import SearchBar from "../SearchBar";
import { useOnScreen } from "hooks/useOnScreen";
import { useDispatch } from "react-redux";
import { SET_NAVBAR_BACKGROUND } from "redux/types";
import { useSearchFormik } from "forms/Search/searchFromik";

function Hero() {
  // handle the search
  const formik = useSearchFormik();

  const ref = useRef(null);

  const isVisible = useOnScreen(ref);

  const dispatch: any = useDispatch();

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  useEffect(() => {
    if (isVisible) {
      dispatch({ type: SET_NAVBAR_BACKGROUND, payload: true });
    } else {
      dispatch({ type: SET_NAVBAR_BACKGROUND, payload: false });
    }
  }, [isVisible]);

  return (
    <>
      {" "}
      <section
        data-aos="zoom-out"
        data-aos-duration="900"
        ref={ref}
        className={`px-[40px] xl:px-[144px] w-full h-screen bg-home_1 bg-cover bg-no-repeat flex items-center justify-center`}
      >
        {/* header and search bar */}
        <div className="flex flex-col xl:flex-row items-center gap-[40px]">
          {/* left side */}
          <div
            className={`xl:w-[50%] font-Avenir_bold text-[30px] sm:text-[38px] xl:text-[48px] text-center xl:text-left duration-1000 ${
              !animate ? "translate-x-[-2000px]" : "translate-x-0"
            }`}
          >
            Connecting Tomorrow's Talent With Today's Opportunities
          </div>

          {/* right side */}
          <div
            className={`xl:w-[50%] flex flex-col gap-[20px] text-center xl:text-left duration-1000 ${
              !animate ? "translate-x-[2000px]" : "translate-x-0"
            }`}
          >
            <p className="xl:pl-[20px] font-Avenir_bold text-[24px]">
              Explore for an intern according to your skills.
            </p>
            <p className="xl:pl-[20px] font-Avenir_regular text-[16px]">
              We have more than 200 interns weekly from more than 100 company
            </p>
            <SearchBar formik={formik} borderWidth={0} />
            <p className="flex items-center justify-center font-Avenir_demi text-red-500">
              {formik.errors.searchTitle}
            </p>
          </div>
        </div>
      </section>
      {/* arrow down icon */}
      <div className="mt-[-100px] flex flex-col items-center gap-[20px]">
        <p className="font-Avenir_demi text-[14px]">Discover Categories</p>
        <a href="#category">
          <img
            className="w-[64px] h-[32px] animate-[moveDown_1.5s_ease-in-out_infinite]"
            src="images/home-down.png"
            alt=""
          />
        </a>
      </div>
    </>
  );
}

export default Hero;

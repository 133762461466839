import {
  GENERATE_EMAIL_OTP_LOADING,
  GENERATE_EMAIL_OTP_SUCCESS,
  GENERATE_EMAIL_OTP_FAILURE,
} from "redux/types";
import { Dispatch } from "redux";
import { createNewItemService } from "services/createNewItemService";
import { notification } from "antd";
import { generateEmailOtpLink } from "constants/endPoints";

export const generateEmailOtpActions =
  (data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: GENERATE_EMAIL_OTP_LOADING, payload: true });
      const response: any = await createNewItemService(
        `${generateEmailOtpLink}`,
        data,
        "",
        "json"
      );

      if (response.status === 200) {
        notification.success({
          message: `${response?.data?.message}`,
        });
        callback();
      } else {
        notification.error({
          message: `${response?.response?.data?.message}`,
        });
      }

      // console.log(response?.response?.data?.message);

      dispatch({ type: GENERATE_EMAIL_OTP_SUCCESS, payload: response });
    } catch (err: any) {
      dispatch({ type: GENERATE_EMAIL_OTP_FAILURE, payload: err });
      notification.error({
        message: `${err?.message}`,
      });
    } finally {
      dispatch({ type: GENERATE_EMAIL_OTP_LOADING, payload: false });
    }
  };

import {
  CloseIcon,
  LogoIcon,
  erroricon,
  passwordhide,
  passwordshow,
  back,
} from "assets/icons";
import { Spin } from "antd";
import { useState } from "react";
import { useUserSignUpFormik } from "forms/JoinUsForms/SginUpForms/userSignUpFormik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";

interface UserSignUpProps {
  activePop: string;
  setActivePop: React.Dispatch<React.SetStateAction<any>>;
}

const UserSignUp = ({ activePop, setActivePop }: UserSignUpProps) => {
  ///////////////////////////////// states
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [country, setCountry] = useState("");

  const formik = useUserSignUpFormik(setActivePop, country);

  //////////////////////////////  handle  loading
  const { phoneSignUpLoading } = useSelector(
    (state: RootState) => state.phoneSignUpReducer
  );

  return (
    <section
      data-aos="zoom-out"
      data-aos-duration="400"
      className="fixed top-0 right-0 w-full h-screen bg-black/55 flex items-center justify-center z-10"
    >
      <div className="relative lg:w-[410px] p-[30px] bg-pro_bg rounded-[15px] flex flex-col gap-[15px] text-pro_text_dark">
        {/************************* logo section  **********************/}
        <div className="relative flex items-center justify-center">
          <div className="absolute top-0 left-0 cursor-pointer">
            {/* <img src={back} alt="back" /> */}
          </div>
          {/* logo */}
          <div>
            <img src={LogoIcon} alt="logo" />
          </div>

          {/* close button */}
          <div
            onClick={() => setActivePop("")}
            className="absolute top-0 right-0 cursor-pointer"
          >
            <img src={CloseIcon} alt="logo" />
          </div>
        </div>

        {/* Let's Begin The Journey */}
        <div className="font-Avenir_bold text-[24px] text-white flex items-center justify-center">
          Let's Begin The Journey
        </div>

        {/*********** phone and password login section  ***********/}
        <Spin spinning={phoneSignUpLoading}>
          <form
            onSubmit={formik?.handleSubmit}
            className="flex flex-col gap-[20px] text-white"
          >
            {/****************** phone number  *********/}
            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-3">
                <PhoneInput
                  inputStyle={{
                    width: "100%",
                    height: "48px",
                    borderRadius: "10px",
                  }}
                  country={"eg"}
                  onlyCountries={["eg"]}
                  dropdownStyle={{
                    color: "black",
                  }}
                  countryCodeEditable={false}
                  onChange={(phone, x, y, valueseparated) => {
                    // the country code
                    const countryCode = valueseparated.split(" ").shift();

                    if (countryCode === "+20") {
                      setCountry("eg");
                    } else if (countryCode === "+971") {
                      setCountry("uae");
                    }
                    const countryCodeLength = countryCode
                      ?.split("")
                      .filter((item: any, idx: number) => idx !== 0)
                      .join("").length;

                    // phone number
                    const phoneNumber = phone.slice(
                      countryCodeLength,
                      phone.length
                    );

                    formik.setFieldValue("phone", phoneNumber);
                  }}
                />
              </div>

              {/* errors section  */}
              <div className="mt-[-5px] w-full flex items-center">
                {formik.errors.phone && (
                  <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                    <img className="w-[22px]" src={erroricon} alt="" />{" "}
                    {formik.errors.phone}
                  </p>
                )}{" "}
              </div>
            </div>

            {/******************** password ***************/}
            <div className="relative flex flex-col">
              <div className="relative flex flex-col">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  onChange={formik.handleChange}
                  className="w-full h-[48px] rounded-[15px] bg-pro_bg border-white border-[1px] pl-[15px] placeholder-white focus:outline-none"
                />

                {/* hide and show password icon  */}
                <div
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute top-[33%] right-[5%] cursor-pointer"
                >
                  {showPassword ? (
                    <img src={passwordhide} alt="hide" />
                  ) : (
                    <img src={passwordshow} alt="show" />
                  )}
                </div>
              </div>
              {/* errors section  */}
              <div className=" w-full flex items-center">
                {formik.errors.password && (
                  <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                    <img className="w-[22px]" src={erroricon} alt="" />{" "}
                    {formik.errors.password}
                  </p>
                )}{" "}
              </div>
            </div>
            {/***************** confirm password ************/}
            <div className="relative flex flex-col">
              <div className="relative flex flex-col">
                <input
                  type={showConfirm ? "text" : "password"}
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  onChange={formik.handleChange}
                  className="w-full h-[48px] rounded-[15px] bg-pro_bg border-white border-[1px] pl-[15px] placeholder-white focus:outline-none"
                />

                {/* hide and show password icon  */}
                <div
                  onClick={() => setShowConfirm(!showConfirm)}
                  className="absolute top-[33%] right-[5%] cursor-pointer"
                >
                  {showConfirm ? (
                    <img src={passwordhide} alt="hide" />
                  ) : (
                    <img src={passwordshow} alt="show" />
                  )}
                </div>
              </div>
              {/* errors section  */}
              <div className=" w-full flex items-center">
                {formik.errors.confirmPassword && (
                  <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                    <img className="w-[22px]" src={erroricon} alt="" />{" "}
                    {formik.errors.confirmPassword}
                  </p>
                )}{" "}
              </div>
            </div>
            {/**************** Next button *****************/}
            <button
              type="submit"
              className="w-full h-[40px] bg-[#DCDEE3] text-pro_text_dark text-[14px] font-Avenir_demi rounded-[15px] hover:text-pro_text_light hover:bg-pro_text_dark duration-300"
            >
              Next
            </button>
          </form>
        </Spin>
      </div>
    </section>
  );
};

export default UserSignUp;

import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { addUserPersonalInfoActions } from "redux/actions/JoinUsAction/SignUp/addUserPersonalInfoActions";

interface userPersonalInfoTypes {
  image: File | null;
  firstName: string;
  lastName: string;
  address: string;
  email: string;
}

export const useUserPersonalInfoFormik = (
  setActivePop: any,
  googelAccountData?: any
) => {
  const dispatch: any = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: googelAccountData ? googelAccountData?.given_name : "",
      lastName: googelAccountData ? googelAccountData?.family_name : "",
      address: "",
      email: googelAccountData ? googelAccountData?.email : "",
      image: null,
    },
    validationSchema: yup.object({
      // image: yup.optional(),
      firstName: yup.string().required("This field is required"),
      lastName: yup.string().required("This field is required"),
      address: yup.string().required("This field is required"),
      email: yup.string().email().required("This field is required"),
    }),

    onSubmit: async (values: userPersonalInfoTypes) => {
      await dispatch(
        addUserPersonalInfoActions(
          {
            FirstName: values.firstName,
            LastName: values.lastName,
            ImageFile: values.image,
            Address: values.address,
            Email: values.email,
          },
          () => {
            setActivePop("userExperience");
          }
        )
      );
    },
  });

  return formik;
};

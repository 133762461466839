import { useEffect, useState } from "react";

interface ProgressBarValue {
  value: number;
}

function ProgressBar({ value }: ProgressBarValue) {
  const backgroundColor = (value: number) => {
    if (value > 50 && value <= 100) {
      return "#E3FF66";
    }
    
    // else if (value < 70 && value > 40) {
    //   return "#F7C500";
    // }
    
    else if (value <= 50 && value >= 0) {
      return "#9AADBF";
    } else {
      return "#F1F1F1";
    }
  };

  const [progress, setProgress] = useState<boolean>(false);

  useEffect(() => {
    setProgress(true);
  }, []);

  return (
    <div className="relative w-full h-[20px] bg-[#d3d9df] rounded-br-[15px] rounded-bl-[15px]">
      <div
        className="h-full transition-all duration-[2s] rounded-br-[15px] rounded-bl-[15px] rounded-tr-[15px]"
        style={{
          width: `${!progress ? 0 : value.toString()}%`,
          backgroundColor: `${backgroundColor(value)}`,
        }}
      ></div>
      <div className="absolute top-0 right-[40%] text-pro_text_dark font-Avenir_demi text-[13px]">
        {value}% Matched
      </div>
    </div>
  );
}

export default ProgressBar;

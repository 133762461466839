import {
  GET_USER_CV_LOADING,
  GET_USER_CV_SUCCESS,
  GET_USER_CV_FAILURE,
} from "redux/types";
import { Dispatch } from "redux";
import { listSingleItemService } from "services/listSingleItemService";
import { getUserCvLink } from "constants/endPoints";

export const getUserCvAction =
  (userId?: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: GET_USER_CV_LOADING, payload: true });
      const response = await listSingleItemService(`${getUserCvLink}`, userId);

      dispatch({ type: GET_USER_CV_SUCCESS, payload: response.data });
      callback && callback(response.data);
    } catch (err) {
      dispatch({ type: GET_USER_CV_FAILURE, payload: err });
    } finally {
      dispatch({ type: GET_USER_CV_LOADING, payload: false });
    }
  };

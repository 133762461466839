import {
  VALIDATE_OTP_RESET_PASSWORD_LOADING,
  VALIDATE_OTP_RESET_PASSWORD_SUCCESS,
  VALIDATE_OTP_RESET_PASSWORD_FAILURE,
} from "redux/types";

const initialState = {
  validateOtpLoading: false,
  validateOtp: null,
  resetToken: null,
  error: null,
};

export const validateOtpResetPasswordReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case VALIDATE_OTP_RESET_PASSWORD_LOADING:
      return { ...state, validateOtpLoading: action.payload };
    case VALIDATE_OTP_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        validateOtp: action.payload,
        resetToken: action?.payload?.data,
      };
    case VALIDATE_OTP_RESET_PASSWORD_FAILURE:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

import {
  GOOGLE_LOGIN_LOADING,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_LOGIN_FAILURE,
  SET_GOOGLE_DATA,
} from "redux/types";

const initialState = {
  googleLoginLoading: false,
  token: null,
  userId: null,
  isCompleted: null,
  role: null,
  username: null,
  email: null,
  completion: null,
  googelAccountData: null,
  errors: null,
};

export const googleLoginReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GOOGLE_LOGIN_LOADING:
      return { ...state, googleLoginLoading: action.payload };
    case GOOGLE_LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        isCompleted: action.payload.isCompleted,
        userId: action.payload.userId,
        username: action.payload.username,
        email: action.payload.email,
        role: action.payload.role,
        completion: action.payload.complition,
      };
    case SET_GOOGLE_DATA:
      return { ...state, googelAccountData: action.payload };
    case GOOGLE_LOGIN_FAILURE:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

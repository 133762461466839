import {
  PHONE_SIGN_UP_LOADING,
  PHONE_SIGN_UP_SUCCESS,
  PHONE_SIGN_UP_FAILURE,
  SIGN_IN_SUCCESS,
} from "redux/types";
import { Dispatch } from "redux";
import { createNewItemService } from "services/createNewItemService";
import { notification } from "antd";
import { phoneSignUpLink } from "constants/endPoints";

export const phoneSignUpActions =
  (data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: PHONE_SIGN_UP_LOADING, payload: true });
      const response: any = await createNewItemService(
        `${phoneSignUpLink}`,
        data,
        "",
        "json"
      );

      dispatch({ type: PHONE_SIGN_UP_SUCCESS, payload: response.data });
      dispatch({ type: SIGN_IN_SUCCESS, payload: response.data });

      if (response?.status === 200) {
        notification.success({
          message: "phone number added successfully",
        });
        callback();
      } else {
        notification.error({
          message: `${response?.response?.data?.message}`,
        });
      }
    } catch (err: any) {
      dispatch({ type: PHONE_SIGN_UP_FAILURE, payload: err });
      // console.log(err);
      notification.error({
        message: `${err}`,
      });
    } finally {
      dispatch({ type: PHONE_SIGN_UP_LOADING, payload: false });
    }
  };

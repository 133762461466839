import { notification } from "antd";
import axios, { AxiosResponse } from "axios";
import store from "redux/store";

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 25000,
  timeoutErrorMessage: "Request Timed out please check your connectivity",
});

instance.interceptors.response.use(
  (response: any) => {
    return response;
  },
  async (error: any) => {
    if (error?.code === "ECONNABORTED") {
      notification.error({
        message: "internet connection issue",
        description: "Please try again!",
      });

      return await Promise.resolve(error);
    }

    const notificationParam = {
      message: "",
      description: "",
      style: {
        zIndex: 99999999999999,
      },
    };

    switch (error.response?.status) {
      case 401:
        // store.dispatch({type: LOG_OUT});
        break;
      case 400:
        // notificationParam.message =
        //   error.response?.data?.message === undefined ||
        //   error.response?.data?.message === null
        //     ? "Bad Request"
        //     : error.response?.data?.message;
        break;
      case 404:
        // notificationParam.message = "Not Found";
        break;
      case 422:
        // notificationParam.message = "Authentication Failed";
        // notificationParam.description =
        //   "Wrong email/password, check your credentials";
        break;
      case 500:
        // notificationParam.message = "Something went wrong";
        break;
      case 508:
        // notificationParam.message = "Time Out";
        // notificationParam.description = "The server terminated an operation";
        break;
      default:
        break;
    }

    if (notificationParam.message) notification.error(notificationParam);

    return await Promise.resolve(error);
  }
);

export default instance;

import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BASE_IMAGE_URL } from "constants/constants";

interface CompanyProps {
  id: number;
  companyName: string;
  companyField: string;
  jobCount: number;
  coverImage: string;
}

const Company: FC<CompanyProps> = ({
  id,
  companyName,
  companyField,
  jobCount,
  coverImage,
}) => {
  const navigate = useNavigate();

  return (
    <div
      data-aos="fade-up"
      data-aos-duration="900"
      // onClick={() => {
      //   navigate(`/companydetails/${id}`);
      // }}
      className=" w-full rounded-[15px] bg-white flex flex-col gap-[10px] shadow-[0px_0px_20px_-10px_black] hover:cursor-pointer"
    >
      <div className=" flex flex-col gap-[10px]">
        {/* company logo pic  */}
        <img
          src={BASE_IMAGE_URL + coverImage}
          alt="cover-logo"
          className="w-full h-[135px] rounded-tr-[15px] rounded-tl-[15px] object-cover object-center"
        />

        <div className="p-[20px] flex flex-col gap-[20px]">
          {/* company name  */}
          <div className="font-Avenir_demi text-[20px] text-pro_text_dark">
            <Link to={`/companydetails/${id}`}>{companyName}</Link>
            <p className="text-[14px] font-Avenir_regular text-[#808184] line-clamp-2">
              {companyField}
            </p>
          </div>

          {/* avilable jobs  */}
          <div className="flex items-center gap-[10px] font-Avenir_regular text-[16px] text-pro_text_dark">
            <div>
              <img src="images/job.png" alt="case" />
            </div>
            <p>{jobCount}</p>
            <p>Jobs</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Company;

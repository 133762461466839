import React from "react";
import BurgerMenu from "./BurgerMenu";

interface LoginTypes {
  showMenu: boolean;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
  formik: any;
}

function MobileMenu({ showMenu, setShowMenu, formik }: LoginTypes) {
  // const [activePop, setActivePop] = useState("");

  // const navigate = useNavigate();

  // const loginFormik = useLoginFormik(setActivePop);
  // const companiesLoginFormik = useCompaniesLoginFormik(setActivePop);
  // const personalInfoFormik = usePersonalInfoFormik(setActivePop);
  // const academicInfoFormik = useAcademicInfoFormik(setActivePop);
  // const experienceFormik = useExperienceFormik(setActivePop);
  // const workLocationFormik = useWorkLocationFormik(
  //   loginFormik.values,
  //   personalInfoFormik.values,
  //   academicInfoFormik.values,
  //   experienceFormik.values,
  //   setActivePop
  // );

  // const companyRegistrationOneFormik =
  //   useCompanyRegistrationOneFormik(setActivePop);

  // const companyRegistrationTwoFormik =
  //   useCompanyRegistrationTwoFormik(setActivePop);

  // const companyRegistrationThreeFormik =
  //   useCompanyRegistrationThreeFormik(setActivePop);

  return (
    <div>
      {/* burger menu icon */}
      <div className="block md:hidden text-center text-[40px] font-Avenir_bold hover:cursor-pointer">
        <i onClick={() => setShowMenu(!showMenu)} className="ri-menu-fill"></i>
      </div>

      {/* burger menu section  */}
      <BurgerMenu
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        formik={formik}
      />
    </div>
  );
}

export default MobileMenu;

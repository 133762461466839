import { Select, Spin } from "antd";
import { DropdownIcon, LogoIcon, erroricon } from "assets/icons";
import { useDropdown } from "hooks/useDropdown";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listFacultiesAction } from "redux/actions/facultiesActions/listFacultiesAction";
import { RootState } from "redux/rootReducer";
import type { DatePickerProps } from "antd";
import { DatePicker, Space } from "antd";
import { academicYears } from "../../../../constants/constants";
import { useUserAcademicInfoFormik } from "forms/JoinUsForms/SginUpForms/userAcademicInfoFormik";

interface UserAcademicInfoProps {
  showAcademicYear: boolean;
  activePop: string;
  setActivePop: React.Dispatch<React.SetStateAction<any>>;
}

const UserAcademicInfo = ({
  showAcademicYear,
  activePop,
  setActivePop,
}: UserAcademicInfoProps) => {
  const dispatch: any = useDispatch();
  const formik = useUserAcademicInfoFormik(setActivePop, showAcademicYear);

  //////////////////////////////////   university dropdown
  const { universitiesLoading, universitiesDropdown } = useDropdown();

  //////////////////////////////////    faculty dropdown
  const { facultiesLoading, faculties } = useSelector(
    (state: RootState) => state.listFacultiesReducer
  );

  const facultiesDropdown = faculties?.map((item: any) => {
    return {
      label: item.facultyNameEn,
      value: item.facultyId,
    };
  });

  useEffect(() => {
    dispatch(listFacultiesAction("1"));
  }, []);

  ///////////////////////////////// graduation date
  const currentYear = new Date().getFullYear();
  const startYear = 2010;
  const graduationYears = [];

  for (let year = startYear; year <= currentYear + 5; year++) {
    graduationYears.push({ year });
  }

  const graduationYearsDropdown = graduationYears?.map(
    (item: any, i: number) => {
      return {
        label: item.year,
        value: item.year,
      };
    }
  );

  ///////////////////////////////// academicYears
  const academicYearsDropdown = academicYears?.map((item: any, i: number) => {
    return {
      label: item.yearName,
      value: item.yearName,
    };
  });

  //////////////////////////////// handle loading
  const { addEducationInfoLoading } = useSelector(
    (state: RootState) => state.addEducationInfoReducer
  );

  // console.log(universitiesDropdown);
  // console.log(facultiesDropdown);

  return (
    <section
      data-aos="zoom-out"
      data-aos-duration="400"
      className="fixed top-0 right-0 w-full h-screen bg-black/55 flex items-center justify-center z-10"
    >
      <div className="relative lg:w-[410px] p-[30px] bg-pro_bg rounded-[15px] flex flex-col gap-[15px] text-pro_text_dark">
        {/*************************** logo section  **********************/}
        <div className="relative flex items-center justify-center">
          {/* logo */}
          <div>
            <img src={LogoIcon} alt="logo" />
          </div>

          {/* close button */}
          <div
            // onClick={() => setActivePop("")}
            className="absolute top-0 right-0 cursor-pointer"
          >
            {/* <img src={CloseIcon} alt="logo" /> */}
          </div>
        </div>

        {/* Fill Your Academic Information */}
        <div className="font-Avenir_bold text-[20px] text-white flex items-center justify-center">
          Fill Your Academic Information
        </div>

        {/**************** Experience options section  *****************/}
        <Spin spinning={addEducationInfoLoading}>
          <form
            onSubmit={formik?.handleSubmit}
            className="flex flex-col gap-[15px] text-white"
          >
            {/* select your university ****************************/}
            <Spin spinning={universitiesLoading}>
              <div className="flex flex-col gap-y-2">
                <Select
                  className="w-full h-[48px] !rounded-[15px] !focus:outline-none placeholder-white/55"
                  placeholder="Universities"
                  suffixIcon={<img src={DropdownIcon} alt="dropdown-icon" />}
                  options={universitiesDropdown}
                  value={
                    formik.values.university === ""
                      ? null
                      : formik.values.university
                  }
                  onSelect={(value: any) => {
                    formik.setFieldValue("university", value);
                    dispatch(listFacultiesAction(value));
                  }}
                />

                {/* errors section  */}
                <div className="mt-[-5px] w-full flex items-center">
                  {formik.errors.university && (
                    <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                      <img className="w-[22px]" src={erroricon} alt="" />{" "}
                      {formik.errors.university}
                    </p>
                  )}
                </div>
              </div>
            </Spin>

            {/* select your faculty *******************************/}
            <Spin spinning={facultiesLoading}>
              <div className="flex flex-col gap-y-2">
                <Select
                  className="w-full h-[48px] rounded-[15px] border-white focus:outline-none placeholder-white/55"
                  placeholder="Faculty"
                  suffixIcon={<img src={DropdownIcon} alt="dropdown-icon" />}
                  options={facultiesDropdown}
                  value={
                    formik.values.faculty === "" ? null : formik.values.faculty
                  }
                  onSelect={(value: any) => {
                    formik.setFieldValue("faculty", value);
                  }}
                />

                {/* errors section  */}
                <div className="mt-[-5px] w-full flex items-center">
                  {formik.errors.faculty && (
                    <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                      <img className="w-[22px]" src={erroricon} alt="" />{" "}
                      {formik.errors.faculty}
                    </p>
                  )}
                </div>
              </div>
            </Spin>

            {/* graduation date ***********************************/}
            <div className="flex flex-col gap-y-2">
              <Select
                className="w-full h-[48px] rounded-[15px] border-white focus:outline-none placeholder-white/55"
                placeholder="Graduation Year"
                suffixIcon={<img src={DropdownIcon} alt="dropdown-icon" />}
                options={graduationYearsDropdown}
                value={
                  formik.values.graduationDate === ""
                    ? null
                    : formik.values.graduationDate
                }
                onSelect={(value: any) => {
                  formik.setFieldValue("graduationDate", value);
                }}
              />

              {/* errors section  */}
              <div className="mt-[-5px] w-full flex items-center">
                {formik.errors.graduationDate && (
                  <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                    <img className="w-[22px]" src={erroricon} alt="" />{" "}
                    {formik.errors.graduationDate}
                  </p>
                )}
              </div>
            </div>

            {/* academic year *************************************/}
            {showAcademicYear && (
              <div className="flex flex-col gap-y-2">
                <Select
                  className="w-full h-[48px] rounded-[15px] border-white focus:outline-none placeholder-white/55"
                  placeholder="Academic year"
                  suffixIcon={<img src={DropdownIcon} alt="dropdown-icon" />}
                  options={academicYearsDropdown}
                  value={
                    formik.values.academicYear === ""
                      ? null
                      : formik.values.academicYear
                  }
                  onSelect={(value: any) => {
                    formik.setFieldValue("academicYear", value);
                  }}
                ></Select>

                {/* errors section  */}
                <div className="mt-[-5px] w-full flex items-center">
                  {formik.errors.academicYear && (
                    <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                      <img className="w-[22px]" src={erroricon} alt="" />{" "}
                      {formik.errors.academicYear}
                    </p>
                  )}
                </div>
              </div>
            )}

            {/* next button *************************************/}

            <button
              type="submit"
              className="w-full h-[40px] bg-[#DCDEE3] text-pro_text_dark text-[14px] font-Avenir_demi rounded-[15px] hover:text-pro_text_light hover:bg-pro_text_dark duration-300"
            >
              Next
            </button>
          </form>
        </Spin>
      </div>
    </section>
  );
};

export default UserAcademicInfo;

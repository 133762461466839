import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { searchAcitons } from "redux/actions/Search/SearchActions";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SEARCH_RESULTS_PATH } from "Routes/paths";
import store from "redux/store";
import { RootState } from "redux/rootReducer";

interface SearchModel {
  searchTitle: string;
}

const useSearchFormik = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const { userId } = useSelector((state: RootState) => state.signInReducer);

  const formik = useFormik({
    initialValues: {
      searchTitle: "",
    },
    validationSchema: yup.object({
      // searchTitle: yup
      //   .string()
      //   .matches(
      //     /^[A-Za-z\s]+$/,
      //     "Job title can only contain letters and spaces"
      //   ),
    }),
    onSubmit: async (values: SearchModel) => {
      searchParams.set("title", values.searchTitle);
      setSearchParams(searchParams);

      const searchObeject: any = {
        title: formik.values.searchTitle,
        userId: userId ?? "",
      };
      if (Number(searchParams.get("country_id")) !== 0) {
        searchObeject.countryId = Number(searchParams.get("country_id"));
      }
      if (Number(searchParams.get("cityId")) !== 0) {
        searchObeject.cityId = Number(searchParams.get("cityId"));
      }
      if (Number(searchParams.get("areaId")) !== 0) {
        searchObeject.areaId = Number(searchParams.get("areaId"));
      }
      if (Number(searchParams.get("vacancyLevelId")) !== 0) {
        searchObeject.vacancyLevelId = Number(
          searchParams.get("vacancyLevelId")
        );
      }
      if (Number(searchParams.get("vacancyWorkPlaceId")) !== 0) {
        searchObeject.vacancyWorkPlaceId = Number(
          searchParams.get("vacancyWorkPlaceId")
        );
      }
      await dispatch(searchAcitons(searchObeject));
      if (window.location.pathname === "/") {
        navigate(`${SEARCH_RESULTS_PATH}?title=${values.searchTitle}`);
      }
    },
  });

  return formik;
};

export { useSearchFormik };

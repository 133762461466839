import
{
    ADD_MAJORS_LOADING,
    ADD_MAJORS_SUCCESS,
    ADD_MAJORS_FAILURE
}
from "redux/types";

const initialState = {
    addMajorsLoading: false,
    success: null,
    errors: null
};

export const addMajorsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ADD_MAJORS_LOADING :
            return {...state, addMajorsLoading: action.payload};
        case ADD_MAJORS_SUCCESS :
            return {...state, success: action.payload};
        case ADD_MAJORS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}
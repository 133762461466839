import {
  GENERATE_OTP_RESET_PASSWORD_LOADING,
  GENERATE_OTP_RESET_PASSWORD_SUCCESS,
  GENERATE_OTP_RESET_PASSWORD_FAILURE,
} from "redux/types";

const initialState = {
  generateOtpLoading: false,
  generateOtp: null,
  errors: null,
};

export const generateOtpResetPassword = (state = initialState, action: any) => {
  switch (action.type) {
    case GENERATE_OTP_RESET_PASSWORD_LOADING:
      return { ...state, generateOtpLoading: action.payload };
    case GENERATE_OTP_RESET_PASSWORD_SUCCESS:
      return { ...state, generateOtp: action.payload };
    case GENERATE_OTP_RESET_PASSWORD_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};

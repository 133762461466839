import api from "api";
import store from "redux/store";

export const listItemsService = async (url: string, params?: string) => {
  const response = await api.get(url + `?${params}`, {
    headers: {
      Authorization: `Bearer ${store.getState()?.signInReducer?.token}`,
    },
  });

  return response;
};

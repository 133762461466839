export type SidebarItemsProps = {
  name: string;
  route: string;
  icon: any;
  highlightedIcon: any;
  selectedIcon: any;
};

export const BASE_IMAGE_URL = `${process.env.REACT_APP_BACKEND_URL}Images/`;
export const BASE_PDF_URL = `${process.env.REACT_APP_BACKEND_URL}UsersCVs/`;

export const careerLevelsSearch = [
  { id: 1, name: "Internship" },
  { id: 2, name: "Entry-Level" },
];

export const workplacesSearch = [
  { id: 1, name: "On-Site" },
  { id: 2, name: "Remotely" },
  { id: 3, name: "Hybrid" },
];

export const academicYears = [
  { yearName: "1st-Year" },
  { yearName: "2nd-Year" },
  { yearName: "3rd-Year" },
  { yearName: "4th-Year" },
  { yearName: "5th-Year" },
  { yearName: "6th-Year" },
  { yearName: "7th-Year" },
];

import {
  LIST_COMPANY_DETAILS_LOADING,
  LIST_COMPANY_DETAILS_SUCCESS,
  LIST_COMPANY_DETAILS_FAILURE,
} from "redux/types";
import { Dispatch } from "redux";
import { listItemsService } from "services/listItemsService";
import { getCompanyDetailsLink } from "constants/endPoints";

export const listCompanyDetailsAction =
  (id: number) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: LIST_COMPANY_DETAILS_LOADING, payload: true });
      const response = await listItemsService(
        `${getCompanyDetailsLink}${id}`,
        ""
      );
      dispatch({ type: LIST_COMPANY_DETAILS_SUCCESS, payload: response.data });
    } catch (err) {
      dispatch({ type: LIST_COMPANY_DETAILS_FAILURE, payload: err });
    } finally {
      dispatch({ type: LIST_COMPANY_DETAILS_LOADING, payload: false });
    }
  };

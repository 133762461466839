import
{
    LIST_FACULTIES_LOADING,
    LIST_FACULTIES_SUCCESS,
    LIST_FACULTIES_FAILURE
}
from "redux/types";

const initialState = {
    facultiesLoading: false,
    faculties: null,
    errors: null
}

export const listFacultiesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_FACULTIES_LOADING :
            return {...state, facultiesLoading: action.payload};
        case LIST_FACULTIES_SUCCESS :
            return {...state, faculties: action.payload};
        case LIST_FACULTIES_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
} 
import { useNavigate } from "react-router-dom";
import { BASE_IMAGE_URL } from "constants/constants";
import { PinIcon } from "assets/icons";
import ProgressBar from "../MatchedInternships/ProgressBar";
import store from "redux/store";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";

interface CompanyJobValue {
  value: number;
  type?: number;
  title: string;
  companyName: string;
  companyLogo: any;
  location: any;
  description: string;
  vacancyId?: number;
  companyId?: number;
  vacancyWorkPlaceId?: number;
}

function CompanyJob({
  value,
  type,
  title,
  companyName,
  location,
  description,
  companyLogo,
  vacancyId,
  companyId,
  vacancyWorkPlaceId,
}: CompanyJobValue) {
  const { userId } = useSelector((state: RootState) => state.signInReducer);
  const navigate = useNavigate();

  return (
    <div
      data-aos="fade-up"
      data-aos-duration="900"
      className=" w-full rounded-[15px] bg-white flex flex-col gap-[10px] shadow-[0px_0px_20px_-10px_black]"
    >
      <div className="w-full p-[20px] flex flex-col gap-[10px]">
        {/* header  */}
        <p
          onClick={() => navigate(`/jobDetails/${vacancyId}`)}
          className="font-Avenir_demi text-[20px] text-pro_text_dark cursor-pointer hover:text-[#69b1ff] duration-300"
        >
          {title}
        </p>

        {/* company name  */}
        <div className="w-full flex items-center gap-[10px] text-pro_text_dark">
          <div>
            <img
              className="w-[35px] h-[35px] rounded-[50%]"
              src={BASE_IMAGE_URL + companyLogo}
              alt=""
            />
          </div>
          <p
            onClick={() => navigate(`/companydetails/${companyId}`)}
            className="font-Avenir_demi text-[16px] cursor-pointer hover:text-[#69b1ff] duration-300"
          >
            {companyName}
          </p>
        </div>

        {/* location  */}
        <div className="w-full flex items-center gap-[10px] text-pro_text_dark">
          <div className="w-[20px] h-[25px]">
            <img src={PinIcon} alt="location" />
          </div>
          <p className="font-Avenir_regular text-[16px]">
            {location?.country?.countryNameEn}, {location?.city?.cityNameEn}
          </p>
          <div className="font-Avenir_demi text-[14px] p-[5px] rounded-[20px] bg-pro_bg text-white">
            {type === 0
              ? ""
              : type === 1
              ? "Internship"
              : type === 2
              ? "EntryLevel"
              : // : type === 3
                // ? "Hybrid"
                ""}
          </div>
          <div className="font-Avenir_demi text-[14px] p-[5px] rounded-[20px] bg-pro_bg text-white">
            {vacancyWorkPlaceId === 0
              ? ""
              : vacancyWorkPlaceId === 1
              ? "OnSite"
              : vacancyWorkPlaceId === 2
              ? "Remotly"
              : vacancyWorkPlaceId === 3
              ? "Hybrid "
              : ""}
          </div>
        </div>

        {/* description */}
        <div className="w-full">
          <p className="w-full font-Avenir_regular text-[13px] text-[#444444] min-h-[60px] max-h-[60px] line-clamp-3 text-ellipsis">
            {description}
          </p>
        </div>
      </div>
      {/* progress bar */}
      <div className="w-full">
        {userId && (
          <ProgressBar
            value={Number(
              value.toString().includes(".")
                ? value.toString().substring(0, 4)
                : value.toString()
            )}
          />
        )}
      </div>
    </div>
  );
}

export default CompanyJob;

import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";

interface UploadCvModel {
  pdfFile: any;
}

const useUploadCVFormik = (setState?: any) => {
  const dispatch: any = useDispatch();

  const { getUserCvSuccess } = useSelector(
    (state: RootState) => state.getUserCvReducer
  );

  const formik = useFormik({
    initialValues: {
      pdfFile: getUserCvSuccess?.fileName,
    },

    onSubmit: async (values: UploadCvModel) => {
      // await dispatch(listProfileDataAction());
      //   () => {
      //     notification.success({
      //       message: "Profile Updated Successfully",
      //       description:
      //         "Please Dont update anything anymore or we will auto set ur description to gay, and gender to non-binry",
      //     });
      //   }
    },
  });

  return formik;
};

export { useUploadCVFormik };

import {
  ADDING_NEW_PASSWORD_LOADING,
  ADDING_NEW_PASSWORD_SUCCESS,
  ADDING_NEW_PASSWORD_FAILURE,
} from "redux/types";

const initialState = {
  newPasswordLoading: false,
  newPasswordSuccess: null,
  errors: null,
};

export const addingNewPasswordReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADDING_NEW_PASSWORD_LOADING:
      return { ...state, newPasswordLoading: action.payload };
    case ADDING_NEW_PASSWORD_SUCCESS:
      return { ...state, newPasswordSuccess: action.payload };
    case ADDING_NEW_PASSWORD_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};

import { LogoIcon, DropdownIcon, erroricon } from "assets/icons/index";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { listSkillsAction } from "redux/actions/skillsAction/listSkillsAction";
import { addSkillsAction } from "redux/actions/userRegistrationActions/addSkillsAction";
import { Select, Spin } from "antd";

interface UserSkillsProps {
  activePop: string;
  setActivePop: React.Dispatch<React.SetStateAction<string>>;
}

const UserSkills = ({ activePop, setActivePop }: UserSkillsProps) => {
  const [selectedSkills, setSelectedSkills] = useState<any[]>([]);

  const dispatch: any = useDispatch();

  const { userId } = useSelector(
    (state: RootState) => state.googleLoginReducer
  );

  ////////////////////// getting the skills list
  useEffect(() => {
    dispatch(listSkillsAction());
  }, []);

  const { skillsLoading, skills } = useSelector(
    (state: RootState) => state.listSkillsReducer
  );

  const skillsDropdown = skills?.map((item: any) => {
    return {
      label: item?.skillNameEn,
      value: item?.skillId,
    };
  });

  ////////////////////// handle the loading state for button
  const { addSkillsLoading } = useSelector(
    (state: RootState) => state.addSkillsReducer
  );

  ////////////////////// handle submit function
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (selectedSkills.length > 0) {
      await dispatch(
        addSkillsAction(
          {
            skillIds: selectedSkills,
          },
          () => {
            setActivePop("userSignUpFinished");
          }
        )
      );
    }
  };

  return (
    <section
      data-aos="zoom-out"
      data-aos-duration="400"
      className="fixed top-0 right-0 w-full h-screen bg-black/55 flex items-center justify-center z-10"
    >
      <div className="relative lg:w-[410px] p-[30px] bg-pro_bg rounded-[15px] flex flex-col gap-[15px] text-pro_text_dark">
        {/*************************** logo section  **********************/}
        <div className="relative flex items-center justify-center">
          {/* logo */}
          <div>
            <img src={LogoIcon} alt="logo" />
          </div>

          {/* close button */}
          <div
            // onClick={() => setActivePop("")}
            className="absolute top-0 right-0 cursor-pointer"
          >
            {/* <img src={CloseIcon} alt="logo" /> */}
          </div>
        </div>

        {/* What's Your Skills ? */}
        <div className="font-Avenir_bold text-[20px] text-white flex items-center justify-center">
          What's Your Skills?
        </div>

        {/**************** Fields Of Work section  *****************/}
        <Spin spinning={addSkillsLoading}>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col gap-[15px] text-white"
          >
            {/* skills select list  *****************************/}
            <Spin
              spinning={skillsLoading}
              wrapperClassName="spin-full-width"
              className="!w-full !bg-transparent"
            >
              <Select
                placeholder="Search For Skills..."
                className="w-full !min-h-[48px] !rounded-[15px] !text-white bg-pro_bg border-white focus:outline-none !placeholder-white"
                suffixIcon={<img src={DropdownIcon} alt="dropdown-icon" />}
                options={skillsDropdown}
                mode="multiple"
                showSearch
                onChange={(value: any) => {
                  setSelectedSkills(value);
                }}
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  (option?.label ?? "").includes(input)
                }
              ></Select>
            </Spin>

            {/* Slected skills *************************************/}
            <div className="w-full flex flex-col gap-4">
              <p className="font-Avenir_regular text-[14px]">
                Selected Skills:
              </p>
              {selectedSkills.length > 0 && (
                <>
                  {/* skills */}
                  <div className="max-h-[170px] overflow-auto scroll flex items-center flex-wrap gap-3">
                    {selectedSkills?.map((skill: any, i: number) => {
                      return (
                        <div key={i}>
                          {/* single skill  */}
                          <div className="flex items-center justify-center px-[10px] h-[48px] border border-pro_text_light rounded-[14px] cursor-pointer font-Avenir_demi bg-pro_text_light text-pro_text_dark">
                            {
                              skills?.find(
                                (singleSkill: any) =>
                                  singleSkill.skillId === skill
                              )?.skillNameEn
                            }
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>

            {/* next button *************************************/}

            <button
              type="submit"
              className="w-full h-[40px] bg-[#DCDEE3] text-pro_text_dark text-[14px] font-Avenir_demi rounded-[15px] hover:text-pro_text_light hover:bg-pro_text_dark duration-300"
            >
              Next
            </button>

            {/* validation warning ******************************/}
            {selectedSkills.length === 0 && (
              <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                <img className="w-[22px]" src={erroricon} alt="" />
                Please choose at least one fields of work
              </p>
            )}
          </form>
        </Spin>
      </div>
    </section>
  );
};

export default UserSkills;

import {
  UPDATE_USER_PROFILE_LOADING,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILURE,
} from "redux/types";
import { Dispatch } from "redux";
import { createNewItemService } from "services/createNewItemService";
import { updateUserProfileByUserIdLink } from "constants/endPoints";

export const updateUserProfileAction =
  (id: string, data: any, callback: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: UPDATE_USER_PROFILE_LOADING, payload: true });
      const response = await createNewItemService(
        `${updateUserProfileByUserIdLink}`,
        data,
        "",
        "formData"
      );
      dispatch({ type: UPDATE_USER_PROFILE_SUCCESS, payload: response.data });
      callback();
    } catch (err) {
      dispatch({ type: UPDATE_USER_PROFILE_FAILURE, payload: err });
    } finally {
      dispatch({ type: UPDATE_USER_PROFILE_LOADING, payload: false });
    }
  };

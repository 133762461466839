import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import ProfileButtons from "../components/Profile/ProfileButtons";
import Footer from "../components/Footer";
import Cv from "../components/CV/Cv";
import AcademicInfo from "../components/Profile/AcademicInfo";
import PersonalInfo from "../components/Profile/PersonalInfo";
import Experience from "../components/Profile/Experience";
import FieldsOfWork from "../components/Profile/FieldsOfWork";
import LocationInformation from "components/Profile/LocationInformation";
import Skills from "components/Profile/Skills";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useUpdateUserProfileFormik } from "forms/updateUserProfileFormik";
import UploadCV from "components/Profile/UploadCV";
import { useUploadCVFormik } from "forms/uploadCVFormik";

function Profile() {
  const [showCv, setShowCv] = useState(false);

  const { profileDataLoading } = useSelector(
    (state: RootState) => state.listProfileDataReducer
  );

  const uploadCvFormik = useUploadCVFormik();
  const formik = useUpdateUserProfileFormik(() => {}, uploadCvFormik);

  return (
    <>
      <Header />

      <main className="relative px-[20px] lg:px-[100px] pt-[100px] w-full">
        {/* Buttons  */}
        <Spin
          wrapperClassName="spin-full-width spin-full-height"
          spinning={profileDataLoading}
        >
          {/* main content  */}
          <form onSubmit={formik.handleSubmit}>
            <ProfileButtons showCv={showCv} setShowCv={setShowCv} />
            <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-[30px] text-pro_text_dark">
              {/* left  side  */}
              <div className="col-span-1 lg:col-span-2 order-1 w-full flex flex-col gap-[20px]">
                {/* personal informaiton */}
                <PersonalInfo formik={formik} />

                {/* Fields Of Work */}
                <FieldsOfWork formik={formik} />

                <Skills formik={formik} />
              </div>

              {/* right side  */}
              <div className="col-span-1 order-2 w-full flex flex-col gap-[20px]">
                {/* upload CV  */}
                <UploadCV formik={uploadCvFormik} />

                {/* Academic informaiton */}
                <AcademicInfo formik={formik} />

                {/* Experience  */}
                <Experience formik={formik} />

                <LocationInformation formik={formik} />
              </div>
            </div>
          </form>
        </Spin>
      </main>

      {/* show the cv  */}
      {showCv && <Cv showCv={showCv} setShowCv={setShowCv} />}

      <Footer />
    </>
  );
}

export default Profile;

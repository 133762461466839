import {
  ADD_USER_PERSONAL_INFO_LOADING,
  ADD_USER_PERSONAL_INFO_SUCCESS,
  ADD_USER_PERSONAL_INFO_FAILURE,
} from "redux/types";
import { Dispatch } from "redux";
import { createNewItemService } from "services/createNewItemService";
import { notification } from "antd";
import { addUserPersonalInfoLink } from "constants/endPoints";

export const addUserPersonalInfoActions =
  (data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ADD_USER_PERSONAL_INFO_LOADING, payload: true });
      const response: any = await createNewItemService(
        `${addUserPersonalInfoLink}`,
        data,
        "",
        "formData"
      );

      dispatch({ type: ADD_USER_PERSONAL_INFO_SUCCESS, payload: response });

      if (response.status === 200) {
        // notification.success({
        //   message: `Success`,
        // });
        callback();
      } else {
        notification.error({
          message: `${response?.response?.data?.message}`,
        });
      }

      // console.log(response);
    } catch (err: any) {
      dispatch({ type: ADD_USER_PERSONAL_INFO_FAILURE, payload: err });

      notification.error({
        message: `${err.message}`,
      });
    } finally {
      dispatch({ type: ADD_USER_PERSONAL_INFO_LOADING, payload: false });
    }
  };

import {
  ADD_SKILLS_LOADING,
  ADD_SKILLS_SUCCESS,
  ADD_SKILLS_FAILURE,
} from "redux/types";
import { Dispatch } from "redux";
import { createNewItemService } from "services/createNewItemService";
import { addSkillsLink } from "constants/endPoints";
import { notification } from "antd";

export const addSkillsAction =
  (data: any, callback: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ADD_SKILLS_LOADING, payload: true });
      const response = await createNewItemService(
        `${addSkillsLink}`,
        data,
        "",
        "json"
      );

      if (response.status === 200) {
        callback();
      } else {
        notification.error({
          message: "",
        });
      }
      dispatch({ type: ADD_SKILLS_SUCCESS, payload: response.data });
    } catch (err) {
      dispatch({ type: ADD_SKILLS_FAILURE, payload: err });
    } finally {
      dispatch({ type: ADD_SKILLS_LOADING, payload: false });
    }
  };

import {
  COMPLETE_REGISTRATION_LOADING,
  COMPLETE_REGISTRATION_SUCCESS,
  COMPLETE_REGISTRATION_FAILURE,
} from "redux/types";
import { Dispatch } from "redux";
import { createNewItemService } from "services/createNewItemService";
import { completeRegisterLink } from "constants/endPoints";
import { notification } from "antd";

export const completeRegistrationAction =
  (callback?: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: COMPLETE_REGISTRATION_LOADING, payload: true });
      const response = await createNewItemService(
        `${completeRegisterLink}`,
        {},
        "",
        "json"
      );
      dispatch({ type: COMPLETE_REGISTRATION_SUCCESS, payload: response.data });

      if (response.status === 200) {
        callback();
      } else {
        notification.error({
          message: "",
        });
      }
    } catch (err) {
      dispatch({ type: COMPLETE_REGISTRATION_FAILURE, payload: err });
    } finally {
      dispatch({ type: COMPLETE_REGISTRATION_LOADING, payload: false });
    }
  };

import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { HOME_PATH } from "Routes/paths";
import { RootState } from "redux/rootReducer";

interface UserProtectedRoutesProps {
  children: React.ReactNode;
}

const UserProtectedRoutes: FC<UserProtectedRoutesProps> = ({ children }) => {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const { userId, role } = useSelector(
    (state: RootState) => state.signInReducer
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  useEffect(() => {
    if (userId === null) {
      navigate(HOME_PATH);
      return;
    }
  }, [userId]);

  return <div className="w-full h-full">{children}</div>;
};

export default UserProtectedRoutes;

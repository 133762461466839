import api from "api";
import store from "redux/store";

export const createNewItemService = async (
  url: string,
  data: any,
  params?: string,
  contentType?: "json" | "formData"
) => {
  const response = await api.post(url + params, data, {
    headers: {
      "Content-Type": !contentType
        ? "multipart/form-data"
        : contentType === "json"
        ? "application/json"
        : "multipart/form-data",

      Authorization: `Bearer ${store.getState()?.signInReducer?.token}`,
    },
  });

  return response;
};

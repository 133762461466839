import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import MobileMenu from "./Header/MobileMenu";
import { LogoBlueIcon } from "assets/icons";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import NavBar from "./Header/NavBar";
import { useUpdateUserProfileFormik } from "forms/updateUserProfileFormik";
import JoinUs from "./Header/JoinUs/JoinUs";

function Header() {
  const formik = useUpdateUserProfileFormik();

  const { set } = useSelector((state: RootState) => state.setNavbarBackground);

  const [showMenu, setShowMenu] = useState(false);

  const [background, setBackground] = useState(false);

  const { token, role, isCompleted } = useSelector(
    (state: RootState) => state.signInReducer
  );

  const { dataComplete } = useSelector(
    (state: RootState) => state.completeRegistrationReducer
  );

  useEffect(() => {}, [token, dataComplete]);

  useEffect(() => {
    if (set) {
      setBackground(true);
    } else {
      setBackground(false);
    }
  }, [set]);

  return (
    <header
      className={`fixed w-full h-[65px] flex items-center justify-between px-[30px] xl:px-[144px] transition-all duration-300 ${
        !background ? "bg-white shadow-lg" : "bg-transparent"
      } z-10`}
    >
      {/* logo section */}
      <div className="w-[98px] h-[33px]">
        <Link to={"/"}>
          <img src={LogoBlueIcon} alt="logo" />
        </Link>
      </div>

      {/* right section */}
      <section className=" h-full">
        {(token && role === "Employer" && isCompleted) ||
        dataComplete?.isCompleted ||
        isCompleted ? (
          <NavBar formik={formik} />
        ) : (
          <JoinUs hidden={true} />
        )}

        {/* mobile burger menu */}
        <MobileMenu
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          formik={formik}
        />
      </section>
    </header>
  );
}

export default Header;

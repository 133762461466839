import
{
    APPLY_TO_JOB_LOADING,
    APPLY_TO_JOB_SUCCESS,
    APPLY_TO_JOB_FAILURE
}
from "redux/types";

const initialState = {
    applyLoading: false,
    success: null,
    errors: null
}

export const applyToJobReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case APPLY_TO_JOB_LOADING :
            return {...state, applyLoading: action.payload};
        case APPLY_TO_JOB_SUCCESS :
            return {...state, success: action.payload};
        case APPLY_TO_JOB_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}
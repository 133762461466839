import React from "react";

function DownloadApp() {
  return (
    <section
      data-aos="fade-up"
      data-aos-duration="900"
      className="relative w-full min-h-[435px] mt-[90px] bg-home_3 bg-cover bg-no-repeat py-4 lg:py-0 flex flex-col lg:flex-row items-center justify-between gap-12 lg:gap-0"
    >
      {/* left side  */}
      <div className="h-full text-white px-[40px] xl:px-[144px] flex flex-col justify-center gap-[20px]">
        <p className="font-Avenir_bold text-[22px] lg:text-[32px] text-center lg:text-left">
          Download Intrn App Soon!
        </p>
        <p className=" font-Avenir_demi text-[14px] lg:text-[24px] text-center lg:text-left">
          Download our application for seamless internship opportunities!
          <br />
          And make it on your hand with the mobile app experience.
        </p>
        <div className="flex flex-col items-center  lg:flex-row gap-[30px]">
          <div>
            <img
              className="w-[172px] h-[48px]"
              src="images/google-play-white.png"
              alt="logo"
            />
          </div>
          <div>
            <img
              className="w-[172px] h-[48px]"
              src="images/app-store-white.png"
              alt="logo"
            />
          </div>
        </div>
      </div>

      {/* mobile shape side */}
      <div className="lg:w-[50%] flex items-center justify-center">
        <img
          className="animate-[moveDown_1.5s_ease-in-out_infinite]"
          src="images/mobile.png"
          alt="logo"
        />
      </div>
    </section>
  );
}

export default DownloadApp;

import {
  LIST_PROFILE_DATA_LOADING,
  LIST_PROFILE_DATA_SUCCESS,
  LIST_PROFILE_DATA_FAILURE,
} from "redux/types";
import { Dispatch } from "redux";
import { listItemsService } from "services/listItemsService";
import { getUserProfileDataByIdLink } from "constants/endPoints";
import store from "redux/store";

export const listProfileDataAction =
  (callback?: any, userId?: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: LIST_PROFILE_DATA_LOADING, payload: true });
      const response = await listItemsService(
        `${getUserProfileDataByIdLink}`,
        `userId=${userId ? userId : store.getState()?.signInReducer?.userId}`
      );

      dispatch({ type: LIST_PROFILE_DATA_SUCCESS, payload: response.data });
      callback && callback(response.data);
    } catch (err) {
      dispatch({ type: LIST_PROFILE_DATA_FAILURE, payload: err });
    } finally {
      dispatch({ type: LIST_PROFILE_DATA_LOADING, payload: false });
    }
  };

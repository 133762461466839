import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { addExperienceAction } from "redux/actions/userRegistrationActions/addExperienceAction";

interface userExperienceTypes {
  study_level: string;
  job_level: string;
}

export const useUserExperienceFormik = (
  setActivePop: any,
  setShowAcademicYear: any
) => {
  const dispatch: any = useDispatch();

  const formik = useFormik({
    initialValues: {
      study_level: "",
      job_level: "",
    },
    validationSchema: yup.object({
      study_level: yup.string().required("This field is required"),
      job_level: yup.string().required("This field is required"),
    }),
    onSubmit: async (values: userExperienceTypes) => {
      await dispatch(
        addExperienceAction(
          {
            graduationStatusId: values.study_level,
            jobLevelId: values.job_level,
          },
          () => {
            setActivePop("userAcademicInfo");
            if (formik.values.study_level === "1") {
              setShowAcademicYear(true);
            }
          }
        )
      );
    },
  });

  return formik;
};

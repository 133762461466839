import {
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from "redux/types";

const initialState = {
  resetPasswordLoading: false,
  resetPassword: null,
  errors: null,
};

export const resetPasswordReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_PASSWORD_LOADING:
      return { ...state, resetPasswordLoading: action.payload };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, resetPassword: action.payload };
    case RESET_PASSWORD_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};

import {
  GET_COMPANY_JOBS_LOADING,
  GET_COMPANY_JOBS_SUCCESS,
  GET_COMPANY_JOBS_FAILURE,
} from "redux/types";
import { Dispatch } from "redux";
import { listItemsService } from "services/listItemsService";
import { getCompanyJobsLink } from "constants/endPoints";

export const getCompanyJobsActions =
  (id: any) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: GET_COMPANY_JOBS_LOADING, payload: true });

      const response = await listItemsService(
        `${getCompanyJobsLink}`,
        `companyId=${id}`
      );

      dispatch({ type: GET_COMPANY_JOBS_SUCCESS, payload: response.data });
    } catch (err) {
      dispatch({ type: GET_COMPANY_JOBS_FAILURE, payload: err });
    } finally {
      dispatch({ type: GET_COMPANY_JOBS_LOADING, payload: false });
    }
  };

export const ERROR_PATH = "*";
export const HOME_PATH = "/";
export const MATCHED_INTERNSHIPS_PATH = "/matchedInternships";
export const SEARCH_RESULTS_PATH = "/searchResults";
export const PROFILE_PATH = "/profile";
export const APPLICATIONS_PATH = "/applications";
export const JOB_DETAILS_PATH = "/jobDetails";
export const COMPANIES_PATH = "/companies";
export const COMPANY_DETAILS_PATH = "/companydetails/:id";
export const CONTACT_US_PATH = "/contactus";

export const DOWNLOAD_CV_PATH = `/cv/:id`;

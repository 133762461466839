import
{
    MAJORS_DROPDOWN_LOADING, 
    MAJORS_DROPDOWN_SUCCESS,
    MAJORS_DROPDOWN_FAILURE
}
from "redux/types";

const initialState = {
    majorsLoading: false,
    majors: null,
    errors: null
}

export const listMajorsDropdownReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case MAJORS_DROPDOWN_LOADING :
            return {...state, majorsLoading: action.payload};
        case MAJORS_DROPDOWN_SUCCESS :
            return {...state, majors: action.payload};
        case MAJORS_DROPDOWN_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}
import { SET_RESET_EMAIL } from "redux/types";

const initailState = {
  resetEmail: null,
};

export const setResetPasswordEmail = (state = initailState, action: any) => {
  switch (action.type) {
    case SET_RESET_EMAIL:
      return { ...state, resetEmail: action.payload };
    default:
      return state;
  }
};

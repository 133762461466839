import
{
    ADD_WORK_LOCATION_LOADING,
    ADD_WORK_LOCATION_SUCCESS,
    ADD_WORK_LOCATION_FAILURE
}
from "redux/types";

const initialState = {
    addWorkLocationLoading: false,
    success: null,
    errors: null
}

export const addWorkLocationReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ADD_WORK_LOCATION_LOADING :
            return {...state, addWorkLocationLoading: action.payload};
        case ADD_WORK_LOCATION_SUCCESS :
            return {...state, success: action.payload};
        case ADD_WORK_LOCATION_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}
import { CloseIcon, LogoIcon, erroricon } from "assets/icons";
import { useState } from "react";
import { useUserExperienceFormik } from "forms/JoinUsForms/SginUpForms/userExperienceFormik";
import { useSelector } from "react-redux";
import { RootState } from "~/redux/rootReducer";
import { Spin } from "antd";

interface UserExperienceProps {
  activePop: string;
  setActivePop: React.Dispatch<React.SetStateAction<any>>;
  setShowAcademicYear: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserExperience = ({
  activePop,
  setActivePop,
  setShowAcademicYear,
}: UserExperienceProps) => {
  const formik = useUserExperienceFormik(setActivePop, setShowAcademicYear);

  const [studyLevel, setStudyLevel] = useState(
    formik.values.study_level === "1"
      ? "student"
      : formik.values.study_level === "2"
      ? "graduated"
      : ""
  );
  const [jobLevel, setJobLevel] = useState(
    formik.values.job_level === "1"
      ? "internship"
      : formik.values.job_level === "2"
      ? "entryLevel"
      : ""
  );

  const { addExperienceLoading } = useSelector(
    (state: RootState) => state.addExperienceReducer
  );

  return (
    <section
      data-aos="zoom-out"
      data-aos-duration="400"
      className="fixed top-0 right-0 w-full h-screen bg-black/55 flex items-center justify-center z-10"
    >
      <div className="relative lg:w-[410px] p-[30px] bg-pro_bg rounded-[15px] flex flex-col gap-[15px] text-pro_text_dark">
        {/*************************** logo section  **********************/}
        <div className="relative flex items-center justify-center">
          {/* logo */}
          <div>
            <img src={LogoIcon} alt="logo" />
          </div>

          {/* close button */}
          <div
            // onClick={() => setActivePop("")}
            className="absolute top-0 right-0 cursor-pointer"
          >
            {/* <img src={CloseIcon} alt="logo" /> */}
          </div>
        </div>

        {/* Let Us Know About Your Experience */}
        <div className="font-Avenir_bold text-[20px] text-white flex items-center justify-center">
          Let Us Know About Your Experience
        </div>

        {/**************** Experience options section  *****************/}
        <Spin spinning={addExperienceLoading}>
          <form
            onSubmit={formik?.handleSubmit}
            className="flex flex-col gap-[20px] text-white"
          >
            {/* Your Are Student or Graduated ********************/}
            <div className="w-full flex flex-col gap-4">
              <p className="font-Avenir_regular text-[14px]">
                Student or Graduate?
              </p>

              {/* options */}
              <div className="flex items-center gap-3">
                {/* student  */}
                <div
                  onClick={() => {
                    setStudyLevel("student");
                    formik.setFieldValue("study_level", "1");
                  }}
                  className={
                    studyLevel === "student"
                      ? "w-full flex items-center justify-center h-[48px] border border-pro_text_light rounded-[14px] cursor-pointer bg-pro_text_light text-dashboard_primary font-Avenir_demi"
                      : "w-full flex items-center justify-center h-[48px] border border-pro_text_light rounded-[14px] cursor-pointer font-Avenir_demi"
                  }
                >
                  Student
                </div>

                {/* Graduated */}
                <div
                  onClick={() => {
                    setStudyLevel("graduated");
                    formik.setFieldValue("study_level", "2");
                  }}
                  className={
                    studyLevel === "graduated"
                      ? "w-full flex items-center justify-center h-[48px] border border-pro_text_light rounded-[14px] cursor-pointer bg-pro_text_light text-dashboard_primary font-Avenir_demi"
                      : "w-full flex items-center justify-center h-[48px] border border-pro_text_light rounded-[14px] cursor-pointer font-Avenir_demi"
                  }
                >
                  Graduate
                </div>
              </div>

              {/* errors section  */}
              <div className="mt-[-10px] w-full flex items-center">
                {formik.errors.study_level && (
                  <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                    <img className="w-[22px]" src={erroricon} alt="" />{" "}
                    {formik.errors.study_level}
                  </p>
                )}
              </div>
            </div>

            {/* What's you Job Level *****************************/}
            <div className="w-full flex flex-col gap-4">
              <p className="font-Avenir_regular text-[14px]">
                What's your Job Level?
              </p>

              {/* options */}
              <div className="flex items-center gap-3">
                {/* Internship */}
                <div
                  onClick={() => {
                    setJobLevel("internship");
                    formik.setFieldValue("job_level", "1");
                  }}
                  className={
                    jobLevel === "internship"
                      ? "w-full flex items-center justify-center h-[48px] border border-pro_text_light rounded-[14px] cursor-pointer bg-pro_text_light text-dashboard_primary font-Avenir_demi"
                      : "w-full flex items-center justify-center h-[48px] border border-pro_text_light rounded-[14px] cursor-pointer font-Avenir_demi"
                  }
                >
                  Internship
                </div>

                {/* Entry Level */}
                <div
                  onClick={() => {
                    setJobLevel("entryLevel");
                    formik.setFieldValue("job_level", "2");
                  }}
                  className={
                    jobLevel === "entryLevel"
                      ? "w-full flex items-center justify-center h-[48px] border border-pro_text_light rounded-[14px] cursor-pointer bg-pro_text_light text-dashboard_primary font-Avenir_demi"
                      : "w-full flex items-center justify-center h-[48px] border border-pro_text_light rounded-[14px] cursor-pointer font-Avenir_demi"
                  }
                >
                  Entry Level
                </div>
              </div>

              {/* errors section  */}
              <div className="mt-[-10px] w-full flex items-center">
                {formik.errors.job_level && (
                  <p className="text-pro_text_light font-Avenir_demi text-[14px] flex items-center gap-1 animate-pulse">
                    <img className="w-[22px]" src={erroricon} alt="" />{" "}
                    {formik.errors.job_level}
                  </p>
                )}
              </div>
            </div>

            {/**************** Next button ***********************/}
            <button
              type="submit"
              className="w-full h-[40px] bg-[#DCDEE3] text-pro_text_dark text-[14px] font-Avenir_demi rounded-[15px] hover:text-pro_text_light hover:bg-pro_text_dark duration-300"
            >
              Next
            </button>
          </form>
        </Spin>
      </div>
    </section>
  );
};

export default UserExperience;

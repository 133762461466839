import
{   
    LIST_MY_APPLICATIONS_LOADING,
    LIST_MY_APPLICATIONS_SUCCESS,
    LIST_MY_APPLICATIONS_FAILURE
}
from "redux/types";

const initialState = {
    myApplicationsLoading: false,
    myApplications: null,
    errors: null
}

export const listMyApplicationsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_MY_APPLICATIONS_LOADING :
            return {...state, myApplicationsLoading: action.payload};
        case LIST_MY_APPLICATIONS_SUCCESS :
            return {...state, myApplications: action.payload};
        case LIST_MY_APPLICATIONS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}
import AppRoutes from "Routes/AppRoutes";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { SET_NAVBAR_BACKGROUND } from "redux/types";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import Aos from "aos";

function App() {
  ReactGA.initialize("G-EBJ3LL0SPB");

  const dispatch: any = useDispatch();

  const { pathname } = useLocation();

  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    if (window.location.pathname.split("/")[1] !== "") {
      dispatch({ type: SET_NAVBAR_BACKGROUND, payload: false });
    }
  }, [pathname]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return (
    <>
      <AppRoutes />
    </>
  );
}

export default App;

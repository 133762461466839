import {
  GENERATE_OTP_LOADING,
  GENERATE_OTP_SUCCESS,
  GENERATE_OTP_FAILURE,
} from "redux/types";

const initialState = {
  generateOtpLoading: false,
  generateOtpSuccess: null,
  errors: null,
};

export const generateOtpWithEmailReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case GENERATE_OTP_LOADING:
      return { ...state, generateOtpLoading: action.payload };
    case GENERATE_OTP_SUCCESS:
      return { ...state, generateOtpSuccess: action.payload };
    case GENERATE_OTP_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { SearchIcon } from "assets/icons";
import { SEARCH_RESULTS_PATH } from "Routes/paths";
import { RootState } from "~/redux/rootReducer";
import { useSelector } from "react-redux";
import { Spin } from "antd";
interface borderValue {
  borderWidth: number;
  formik?: any;
}

function SearchBar({ borderWidth, formik }: borderValue) {
  const { searchDataLoading } = useSelector(
    (state: RootState) => state.searchDataReducer
  );

  return (
    <Spin spinning={searchDataLoading}>
      <form
        data-aos="fade-left"
        data-aos-duration="900"
        onSubmit={formik.handleSubmit}
        className="w-full mt-[24px]"
      >
        <label className="relative" htmlFor="homeSearch">
          <input
            style={{
              border: `${borderWidth}px solid #9AADBF`,
            }}
            className="w-full h-[56px] rounded-[40px] px-[30px] focus:outline-none !placeholder-[#808184] !text-[#142644] !caret-pro_text_dark"
            type="search"
            id="searchTitle"
            placeholder="Search for jobs..."
            value={formik.values.searchTitle}
            onChange={formik.handleChange}
          />

          <button
            type="submit"
            className="absolute top-[-68%] right-[.2%] w-[50px] h-[50px] bg-pro_text_dark flex items-center justify-center rounded-[50%] cursor-pointer"
          >
            <img src={SearchIcon} alt="search" />
          </button>
        </label>
      </form>
    </Spin>
  );
}

export default SearchBar;

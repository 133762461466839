interface JobDescriptionProps {
  description?: string;
}

const JobDescription = ({ description }: JobDescriptionProps) => {
  return (
    <div
      data-aos="fade-up"
      data-aos-duration="900"
      className="relative w-full rounded-[15px] bg-white flex flex-col gap-[10px] shadow-[0px_0px_20px_-10px_black] text-pro_text_dark"
    >
      <div className="p-[20px] flex flex-col gap-[10px]">
        {/* header  */}
        <p className="font-Avenir_demi text-[20px] text-pro_text_dark">
          Job Description
        </p>

        <p className="font-Avenir_regular text-[14px]">{description}</p>
      </div>
    </div>
  );
};

export default JobDescription;

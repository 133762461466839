import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MATCHED_INTERNSHIPS_PATH } from "Routes/paths";
import { RootState } from "redux/rootReducer";
import { Spin } from "antd";
import { completeRegistrationAction } from "redux/actions/userRegistrationActions/completeRegistrationAction";

interface UserSignUpFinishedProps {
  activePop: string;
  setActivePop: React.Dispatch<React.SetStateAction<string>>;
}

const UserSignUpFinished = ({
  activePop,
  setActivePop,
}: UserSignUpFinishedProps) => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const { completeRegistrationLoading } = useSelector(
    (state: RootState) => state.completeRegistrationReducer
  );

  const { userId } = useSelector((state: RootState) => state.signInReducer);

  const { success } = useSelector(
    (state: RootState) => state.addUserInfoReducer
  );

  const handleSubmit = async () => {
    await dispatch(
      completeRegistrationAction(() => {
        navigate(MATCHED_INTERNSHIPS_PATH);
      })
    );
  };
  return (
    <section
      data-aos="zoom-out"
      data-aos-duration="400"
      className="fixed top-0 right-0 w-full h-screen bg-black/55 flex items-center justify-center z-10"
    >
      <div className="relative w-[90%] lg:w-[450px] p-[30px] bg-pro_bg rounded-[15px] flex flex-col gap-[25px] text-pro_text_dark">
        <div className="flex items-center justify-center">
          <img src="images/done.png" alt="done" />
        </div>
        {/* Thanks Ahmed Your Profile Is Completed Successfully */}
        <div className="font-Avenir_bold text-[20px] lg:text-[24px] text-balance text-white flex items-center justify-center">
          <p className="text-center">
            Thanks{" "}
            {/* {data?.given_name && data?.given_name !== ""
              ? data?.given_name
              : success?.firstName}{" "} */}
            Your Profile Is Completed Successfully
          </p>
        </div>

        {/* Next button  */}

        <Spin spinning={completeRegistrationLoading}>
          <button
            type="submit"
            onClick={handleSubmit}
            className="w-full min-h-[48px] bg-[#DCDEE3] text-pro_text_dark text-[14px] font-Avenir_demi rounded-[15px] hover:text-pro_text_light hover:bg-pro_text_dark duration-300"
          >
            Discover Opportunities
          </button>
        </Spin>
      </div>
    </section>
  );
};

export default UserSignUpFinished;

import
{
    LIST_PROFILE_DATA_LOADING, 
    LIST_PROFILE_DATA_SUCCESS,
    LIST_PROFILE_DATA_FAILURE
}
from "redux/types";

const initialState = {
    profileDataLoading: false,
    profileData: null,
    errors: null
}

export const listProfileDataReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_PROFILE_DATA_LOADING :
            return {...state, profileDataLoading: action.payload};
        case LIST_PROFILE_DATA_SUCCESS :
            return {...state, profileData: action.payload};
        case LIST_PROFILE_DATA_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}
import { SEARCH_LOADING, SEARCH_SUCCESS, SEARCH_FAILURE } from "redux/types";

const initialState = {
  searchDataLoading: false,
  searchData: null,
  errors: null,
};

export const searchDataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SEARCH_LOADING:
      return { ...state, searchDataLoading: action.payload };
    case SEARCH_SUCCESS:
      return { ...state, searchData: action.payload };
    case SEARCH_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};

// applications
export const ApplyToJobLink = "api/applications/apply";
export const GetMyApplicationsByUserIdLink =
  "api/applications/GetMyApplicationsByUserId";

// areas
export const GetAreasByCityIdLink = "api/Areas/GetByCityId";

// cities
export const getCityByIdLink = "api/Cities/GetCitiesBy";

// company
export const getCompanyDetailsLink = "api/Companies/GetCompanyBy";
export const getCompanyJobsLink = "api/FlowCompany/GetCompanyVacansiesForUser";
export const getAllCompaniesLink = "api/Companies/GetAllCompanies";

// countries
export const getAllCountriesLink = "api/Countries/GetAll";

// cv link
export const getUserCvLink = "api/UploadCV/getUserCV";
export const updateUserCvLink = "api/UploadCV/updateUserCV";
export const uploadCvLink = "api/UploadCV/upload-cv";

// Faculties
export const getFacultiesByUniversityIdLink = "/api/Faculties/GetFacultiesBy";

// Vacancies
export const getAllVacanciesLink = "api/Vacancies/GetAllVacancies";

////////////////////////// join us
// reset password
export const generateOtpResetPasswordLink = "api/Auth/generate-otp";
export const resetPasswordLink = "api/Auth/reset-password";
export const validateOtpResetPasswordLink = "api/Auth/validate-otp";

// sign in
export const loginWithEmailLink = "api/NewAuth/LoginWithEmail";

// sign up
export const addUserPersonalInfoLink = "api/NewUserProfiles/AddFormUserInfo";
export const generateEmailOtpLink = "api/NewAuth/generate-otp-Register";
export const generatePhoneOtpLink = "api/Auth/generate-phone-otp";
export const phoneSignUpLink = "api/NewAuth/phone-signup";
export const validateEmailOtpLink = "api/NewAuth/validate-otp-register";
export const validatePhoneOtpLink = "api/Auth/validate-phone-otp";
///////////////////////////////////////////////////////////

// majors
export const getAllMajorsOldLink =
  "api/Majors/GetAllMajorsAsyncGroupedByCategory";
export const getAllMajorsLink = "api/Majors/GetAll";

// matched interns
export const getSuggestedJobsForUserLink =
  "api/UserProfiles/SuggestedJobsForUser";

// password
export const changePasswordLink = "api/Auth/Change-password";
export const resetPasswordWithOtpLink = "api/Auth/reset-password-with-otp";

// profile data
export const getUserProfileDataByIdLink =
  "api/UserProfiles/GetUserProfileByUserId";
export const updateUserProfileByUserIdLink =
  "api/UserProfiles/EditUserProfileByUserId";

// search
export const searchVacanciesLink =
  "api/Vacancies/SearchVacanciesByMatchedJobs?minMatchPercentage=0&maxMatchPercentage=100";

// skills
export const getAllSkillsLink = "api/Skills/GetAll";

// universities
export const getAllUniversitiesLink = "api/Universities/GetAll";

// user register action
export const addEducationInfoLink = "api/NewUserProfiles/EditFormEducation";
export const addExperienceLink = "api/NewUserProfiles/EditFormExperience";
export const addMajorsLink = "api/NewUserProfiles/EditFormMajors";
export const addSkillsLink = "api/NewUserProfiles/EditFormSkills";
export const addUserInfoLink = "api/UserProfiles/AddFormUserInfo";
export const addWorkLocationLink = "api/NewUserProfiles/EditFormLocation";
export const completeRegisterLink = "api/NewAuth/complete";

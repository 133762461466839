import {
  LIST_CITIES_LOADING,
  LIST_CITIES_SUCCESS,
  LIST_CITIES_FAILURE,
} from "redux/types";
import { Dispatch } from "redux";
import { listItemsService } from "services/listItemsService";
import { getCityByIdLink } from "constants/endPoints";

export const listCitiesAction = (id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: LIST_CITIES_LOADING, payload: true });
    const response = await listItemsService(`${getCityByIdLink}${id}`);
    dispatch({ type: LIST_CITIES_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: LIST_CITIES_FAILURE, payload: err });
  } finally {
    dispatch({ type: LIST_CITIES_LOADING, payload: false });
  }
};

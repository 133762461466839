import React, { useEffect } from "react";
import Header from "../components/Header";
import JobDetalisComponent from "../components/JobDetalis/JobDetalisComponent";
import Responsibilities from "../components/JobDetalis/Responsibilities";
import Requirements from "../components/JobDetalis/Requirements";
import Footer from "../components/Footer";
import JobCompany from "../components/JobDetalis/JobCompany";
import MatchIntern from "../components/MatchedInternships/MatchIntern";
import { Spin } from "antd";
import JobDescription from "components/JobDetalis/JobDescription";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { MatchedInternsAction } from "redux/actions/matchedInterns/matchedInternsActions";
import { JobDetailsAction } from "redux/actions/getJobById/getJobByIdActions";
import { useParams } from "react-router-dom";

function JobDetails() {
  const dispatch: any = useDispatch();

  const { userId } = useSelector((state: RootState) => state.signInReducer);
  const { Id } = useParams();

  useEffect(() => {
    dispatch(MatchedInternsAction(userId));
    dispatch(JobDetailsAction(Id));
  }, [userId, dispatch, Id]);

  const { matchedInterns, matchedInternsLoading } = useSelector(
    (state: RootState) => state.MatchedInternsReducer
  );
  const { jobDetails, jobDetailsLoading } = useSelector(
    (state: RootState) => state.JobDetailsReducer
  );

  return (
    <>
      <Header />

      <main className="px-[20px] xl:px-[144px] pt-[150px] w-full">
        {/* main content  */}
        <section className="w-full grid  grid-cols-2 md:grid-cols-3 gap-[30px]">
          {/* left  side  */}
          <div className="col-span-2 w-full flex flex-col gap-[20px]">
            {/* job main details  */}
            <Spin spinning={jobDetailsLoading}>
              <div>
                <JobDetalisComponent jobDetails={jobDetails} />
              </div>
            </Spin>

            {/* job description  */}
            <Spin spinning={jobDetailsLoading}>
              <div>
                <JobDescription
                  description={jobDetails?.matchedVacancy?.description}
                />
              </div>
            </Spin>

            {/* Responsibilities */}
            <Spin spinning={jobDetailsLoading}>
              <div>
                <Responsibilities
                  responsibilities={jobDetails?.matchedVacancy?.responsbilities}
                />
              </div>
            </Spin>

            {/* JobRequirements */}
            <Spin spinning={jobDetailsLoading}>
              <div>
                <Requirements
                  requirements={jobDetails?.matchedVacancy?.requirements}
                />
              </div>
            </Spin>
          </div>

          {/* right side  */}
          <div className="col-span-2 md:col-span-1 w-full flex flex-col gap-[20px]">
            {/* company Detalis  */}
            <Spin spinning={jobDetailsLoading}>
              <JobCompany data={jobDetails} />
            </Spin>

            {/* search */}
            {/* <div className="p-[20px] w-full rounded-[15px] bg-white shadow-[0px_0px_20px_-10px_black]">
              <SideSearch
                title="Find Another Jobs"
                subTitle="Career Level"
                searchBar={true}
                showMore={false}
                checkboxs={["All", "Internship", "Fresh-Graduate"]}
                borderBottom={0}
                placeholder="Search For Jobs..."
              >
                <Button
                  bgColor="#142644"
                  fontSize={16}
                  px={40}
                  py={8}
                  textColor="#E3FF66"
                  text="Search"
                />
              </SideSearch>
            </div> */}
          </div>
        </section>

        {/* lower content  */}
        <section
          data-aos="fade-up"
          data-aos-duration="900"
          className="my-[100px] flex flex-col gap-[20px]"
        >
          {userId && (
            <p className="font-Avenir_bold text-[24px]">
              Another Suggested Jobs
            </p>
          )}

          <Spin spinning={matchedInternsLoading}>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-[30px] justify-items-center ">
              {matchedInterns?.slice(0, 4).map((intern: any, i: number) => {
                return (
                  <div className="w-full" key={i}>
                    <MatchIntern intern={intern} />
                  </div>
                );
              })}
            </div>
          </Spin>
        </section>
      </main>

      {/* footer  */}
      <Footer />
    </>
  );
}

export default JobDetails;

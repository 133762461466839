import {
  GET_COUNTRIES_SEARCH_LOADING,
  GET_COUNTRIES_SEARCH_SUCCESS,
  GET_COUNTRIES_SEARCH_FAILURE,
} from "redux/types";

const initailState = {
  countriesSearchLoading: null,
  countriesSearch: null,
  errors: null,
};

export const listCountriesSearchReducer = (state = initailState, action: any) => {
  switch (action.type) {
    case GET_COUNTRIES_SEARCH_LOADING:
      return { ...state, countriesSearchLoading: action.payload };
    case GET_COUNTRIES_SEARCH_SUCCESS:
      return { ...state, countriesSearch: action.payload };
    case GET_COUNTRIES_SEARCH_FAILURE:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};

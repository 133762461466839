import { useEffect, useRef, useState } from "react";
import Button from "../Button";
import { useExactlyOnScreen } from "hooks/useOnExactlyOnScreen";

const JoinUs = () => {
  const ref = useRef(null);

  const isVisible = useExactlyOnScreen(ref);

  const [animate, setAnimate] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (isVisible && firstRender) {
      setAnimate(true);
      setFirstRender(false);
    }
  }, [isVisible]);

  return (
    <section
      data-aos="fade-up"
      data-aos-duration="900"
      ref={ref}
      className={`w-full min-h-[435px] mt-[100px] bg-home_2 bg-cover bg-no-repeat flex items-center justify-center overflow-x-hidden duration-1000 ${
        animate ? "opacity-100" : "opacity-30"
      }`}
    >
      <div className="w-full px-[40px] lg:px-[144px] py-4 lg:py-0 flex flex-col lg:flex-row items-center justify-between gap-12 lg:gap-0">
        {/* left side */}
        <div
          className={`lg:w-[50%]  duration-1000 ${
            animate ? "translate-x-0" : "translate-x-[-2000px]"
          }`}
        >
          <img
            className="animate-[moveDown_1.5s_ease-in-out_infinite]"
            src="images/join-us.png"
            alt=""
          />
        </div>

        {/* right side */}
        <div
          className={`lg:w-[50%] 2xl:px-[140px] flex flex-col gap-[30px] lg:gap-[10px] duration-1000 ${
            animate ? "translate-x-0" : "translate-x-[2000px]"
          }`}
        >
          <p className="font-Avenir_demi text-[14px] lg:text-[24px] text-white text-center lg:text-left">
            If you're an employer?
          </p>
          <p className="font-Avenir_bold text-[22px] lg:text-[32px] text-white text-center lg:text-left">
            Join more than 100 companies and add your intern add for free
          </p>
          <div className="flex items-center justify-center lg:justify-start">
            <Button
              bgColor="#142644"
              fontSize={18}
              px={56}
              py={11}
              textColor="#E3FF66"
              text="Add Intern Ad"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default JoinUs;

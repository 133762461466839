import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { phoneSignUpActions } from "redux/actions/JoinUsAction/SignUp/phoneSignUpActions";
import { RootState } from "redux/rootReducer";

interface userSignUpTypes {
  phone: string;
  password: string;
  confirmPassword: string;
}

export const useUserSignUpFormik = (setActivePop: any, country: string) => {
  const dispatch: any = useDispatch();

  const { googelAccountData } = useSelector(
    (state: RootState) => state.googleLoginReducer
  );

  const formik = useFormik({
    initialValues: {
      phone: "",
      // otp: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: yup.object({
      phone:
        country === "eg"
          ? yup
              .string()
              .matches(
                /^(?:\+20)?(?:\+0)?(10|11|12|15)\d{8}$/,
                "Invalid phone number"
              )
              .required("This field is required")
          : country === "uae"
          ? yup
              .string()
              .matches(
                /^(?:\+971|0)?(50|55|56|52|58)\d{7}$/,
                "Invalid phone number"
              )
              .required("This field is required")
          : yup.string().required("This field is required"),
      // otp: yup.string().required("This field is required"),
      password: yup
        .string()
        .min(8, "password must be at least 8 characters long")
        .matches(
          new RegExp(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*-_?&#]{8,}$/
          ),
          "Must contain lowercase, uppercase, number, and one special character"
        )
        .required("This field is required"),
      confirmPassword: yup
        .string()
        .min(8, "Password must be at least 8 characters long")
        .oneOf([yup.ref("password")], "Passwords dosen't match")
        .required("This field is required"),
    }),

    onSubmit: async (values: userSignUpTypes) => {
      await dispatch(
        phoneSignUpActions(
          {
            phoneNumber: country === "eg" ? `0${values.phone}` : values.phone,
            password: values.confirmPassword,
            userRole: "User",
            // socialId: googelAccountData?.id,
          },

          () => {
            setActivePop("userPersonalinfo");
          }
        )
      );
    },
  });

  return formik;
};

import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { addEducationInfoAction } from "redux/actions/userRegistrationActions/addEducationInfoAction";

interface userAcademicInfoTypes {
  university: string;
  faculty: string;
  academicYear: string;
  graduationDate: string;
}

export const useUserAcademicInfoFormik = (
  setActivePop: any,
  showAcademicYear: boolean
) => {
  const dispatch: any = useDispatch();

  const { userId } = useSelector(
    (state: RootState) => state.googleLoginReducer
  );

  const formik = useFormik({
    initialValues: {
      university: "",
      faculty: "",
      academicYear: "",
      graduationDate: "",
    },
    validationSchema: yup.object({
      university: yup.string().required("This field is required"),
      faculty: yup.string().required("This field is required"),
      graduationDate: yup.string().required("This field is required"),
      academicYear: showAcademicYear
        ? yup.string().required("This field is required")
        : yup.string().optional(),
    }),
    onSubmit: async (values: userAcademicInfoTypes) => {
      await dispatch(
        addEducationInfoAction(
          {
            // userId: userId,

            universityId: values.university,
            facultyId: values.faculty,
            graduationDate: values.graduationDate.toString(),
            academicYear: values.academicYear,
          },
          () => {
            setActivePop("userWorkLocation");
          }
        )
      );
    },
  });

  return formik;
};

import api from "api";
import store from "redux/store";

export const listSingleItemService = async (url: string, id: string) => {
  const response = await api.get(url + id, {
    headers: {
      Authorization: `Bearer ${store.getState()?.signInReducer?.token}`,
    },
  });

  return response;
};
